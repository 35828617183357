import React, { useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import Colors from "../../../../constants/Colors"
import Layout from "../../../../constants/Layout"
import ModalSelectorCustom from "../../../modalSelectors/ModalSelectorCustom"
import { ShadowStyles } from "../../../../styles"

export function SnakSammenDuration({ duration, handleDurationChange }) {
  const durationsRef = useRef([
    { value: 15, label: txt('videoChat.volunteer.create.duration15') },
    { value: 30, label: txt('videoChat.volunteer.create.duration30') },
    { value: 45, label: txt('videoChat.volunteer.create.duration45') },
    { value: 60, label: txt('videoChat.volunteer.create.duration60') },
  ])

  return (
    <View>
      <CustomText font="gotham-bold-small" style={{ marginTop: 12, marginBottom: 12 }}>
        {txt('videoChat.volunteer.create.duration')}
      </CustomText>
        <ModalSelectorCustom
          data={durationsRef.current}
          keyExtractor={(data) => data.value}
          labelExtractor={(data) => data.label}
          initValue={duration.toString()}
          onChange={handleDurationChange}
          backdropPressToClose={true}
          animationType="fade"
          cancelText={txt('videoChat.volunteer.create.close')}
          style={[styles.modalCustom, { paddingVertical: 2 }]}
          initValueTextStyle={styles.textSmall}
          selectStyle={{ borderColor: Colors.transparent }}
          selectTextStyle={styles.textSmall}
          optionTextStyle={[styles.textSmall, { textAlign: 'center' }]}
          optionContainerStyle={styles.optionContainer}
          cancelStyle={styles.optionContainer}
          cancelTextStyle={[styles.textSmall, { textAlign: 'center' }]}
        />
    </View>
  )
}

const styles = StyleSheet.create({
  modalCustom: {
    backgroundColor: Colors.white,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.redCrossGrey,
    ...ShadowStyles.unsetShadows,
  },
  optionContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    maxHeight: 500,
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
    alignSelf: 'center',
  },
  textSmall: {
    fontFamily: 'gotham-book',
    fontSize: 15,
    color: Colors.text,
    textAlign: 'left',
  },
})
