import React, { useEffect, useRef, useState } from 'react'
import { View, ScrollView, Platform, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import { RoundBtn } from '../../buttons/StyledButtons'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { ShadowStyles, SpacingStyles, TypographyStyles } from '../../../styles'
import { CustomSnackbar } from '../../snackbars/Snackbar'
import { UploadImage } from '../../../services/api/UploadImage'
import { CustomText } from '../../text/StyledText'
import { Picker } from '@react-native-picker/picker'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import Colors from '../../../constants/Colors'
import { useSelector } from 'react-redux'
import * as AddressService from '../../../services/api/Addresses'
import { getMunicipality, municipalities } from '../../../constants/Municipalities'
import { useRoute } from '@react-navigation/native'
import { getFacilties } from '../../../services/api/Unions'
import ChooseFacultyItem from './ChooseFacultyItem'
import store from '../../../redux/store'
import imageBobl from '../../../assets/images/bobl.png'
import CustomPressable from "../../pressables/CustomPressable"
import ModalSelectorCustom from "../../modalSelectors/ModalSelectorCustom"

export default function CreateUnion(props) {
  const user = useSelector((state) => state?.user)
  const [union, setUnion] = useState({
    title: '',
    body: '',
    url: '',
    imgId: null,
    municipalityId: user.municipality_id || 0,
    municipalityName: '',
    image: {},
    address: '',
    city: '',
    postalCode: null,
    latlng: null,
  })
  const [unionWasNotCreated, setUnionWasNotCreated] = useState(false)
  const [isAddressValid, setIsAddressValid] = useState(true)
  const [isPostalValid, setIsPostalValid] = useState(true)
  const [isMissingPostalCode, setIsMissingPostalCode] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const isUnionValid = true
  const uniqueValue = 1
  const SUGGESTIONS_PER_PAGE = 4
  const timeout = useRef(null)

  const [faculties, setFaculties] = useState([])
  const [facultiesVisible, setFacultiesVisible] = useState(false)
  const [selectedFaculties, setSelectedFaculties] = useState(new Set())
  const [isMissingFaculties, setIsMissingFaculties] = useState(false)

  const route = useRoute()
  const universeName = route.params?.universe?.toLowerCase()
  const universe = store.getState().user.universes.find((u) => u.name === universeName)

  useEffect(() => {
    getFacilties().then(setFaculties)
    const municipality = getMunicipality(user.municipality_id)
    updateUnion({ municipalityName: municipality.name })
  }, [route.params])

  const setImage = (image, rotation) => {
    setUnion((prev) => ({ ...prev, image: { image, rotation } }))
  }

  const updateUnion = (update) => {
    setUnion((prev) => ({ ...prev, ...update }))
  }

  const keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') return <KeyboardSpacer />
  }

  function checkURL(url) {
    if (url.startsWith('http') || url === '') return url
    else return 'http://' + url
  }

  const uploadImg = () => {
    if (union.image) {
      return UploadImage(union.image.image, union.image.rotation).catch((err) => {
        if (__DEV__) console.log('IMAGE UPLOAD FAIL ', err)
      })
    }
  }

  function validateUnion() {
    const { title, body } = union
    if (title.length > 0) updateUnion({ titleTooShort: false })
    if (body.length > 0) updateUnion({ descriptionTooShort: false })
    if (!isPostalValid) return false

    if (title.length < 1 && body.length < 1) {
      updateUnion({ titleTooShort: true, descriptionTooShort: true })
      return false
    } else if (title.length < 1) {
      updateUnion({ titleTooShort: true })
      return false
    } else if (body.length < 1) {
      updateUnion({ descriptionTooShort: true })
      return false
    } else if (selectedFaculties.size < 1 && universe?.id === 12) {
      setIsMissingFaculties(true)
      return false
    }
    return true
  }

  const createUnionFull = async () => {
    const isAddressVaild = await validateAddress()
    if (validateUnion() && isAddressVaild) {
      const imgId = await uploadImg()
      const data = {
        title: union.title,
        body: union.body,
        url: checkURL(union.url),
        municipality_id: parseInt(union.municipalityId, 10),
        img_id: imgId,
        address: union.address,
        city: union.city,
        postal_code: union.postalCode,
        lat: union.latlng?.lat,
        lng: union.latlng?.lng,
      }
      if (universe?.id === 12) {
        data.faculties = Array.from(selectedFaculties)
      }
      const unionCreated = await props.createUnion(data)
      if (unionCreated) props.navigateToMyUnions()
    }
  }

  function pickMunicipality() {
    return (
      <ModalSelectorCustom
        data={municipalities}
        keyExtractor={(data) => data.id?.toString()}
        labelExtractor={(data) => data.name}
        key={(data) => data.id}
        initValue={union.municipalityName || txt('signupField.municipalityPlaceholder')}
        onChange={(value) => updateUnion({ municipalityId: value.id })}
      />
    )
  }

  const validateAddress = async () => {
    const { address, postalCode } = union
    if (address?.length > 0) {
      if (!postalCode) {
        setIsMissingPostalCode(true)
        return false
      } else setIsMissingPostalCode(false)

      const data = await AddressService.getAddress(address, postalCode)
      if (data) {
        setIsAddressValid(true)
        return true
      }
      return false
    } else {
      // empty address is valid
      updateUnion({ latlng: { lat: null, lng: null } })
      setIsAddressValid(true)
      setIsMissingPostalCode(false)
      return true
    }
  }

  const handleAddressChange = async (value) => {
    clearTimeout(timeout.current)
    updateUnion({ address: value })
    timeout.current = setTimeout(async () => {
      const suggestions = await AddressService.getSuggestions(value)
      if (suggestions.length === 0 && value) setIsAddressValid(false)
      else setIsAddressValid(true)
      setSuggestions(suggestions)
    }, 800)
  }

  async function handleSuggestionClick(suggestion) {
    const address = suggestion.tekst.split(',')[0]
    const otherInfo = suggestion.adgangsadresse
    const data = await AddressService.getAddress(address, otherInfo.postnr)
    const latlng = data.adgangsadresse.adgangspunkt.koordinater
    updateUnion({
      address: address,
      postalCode: otherInfo.postnr,
      city: otherInfo.postnrnavn,
      latlng: { lat: latlng[1], lng: latlng[0] },
    })
    setSuggestions([])
  }

  const renderSuggestions = () => (
    <View style={styles.suggestionsContainer}>
      {suggestions.map((suggestion, index) => {
        const borderWidth = index !== suggestions.length - 1 ? 1 : 0
        return (
          <CustomPressable
            style={[styles.suggestion, { borderBottomWidth: borderWidth }]}
            key={index.toString()}
            onPress={() => handleSuggestionClick(suggestion)}
          >
            <CustomText>{suggestion.tekst}</CustomText>
          </CustomPressable>
        )
      })}
    </View>
  )

  const handlePostalCodeChange = async (postalCode) => {
    clearTimeout(timeout.current)
    postalCode = postalCode.replace(/[^0-9]/g, '')
    if (postalCode.length === 0) setIsPostalValid(true)
    if (postalCode.length > 0) {
      timeout.current = setTimeout(async () => {
        const city = await AddressService.getCityFromPostalCode(postalCode)
        updateUnion({ city: city ?? '' })
        setIsPostalValid(city !== null)
      }, 400)
    }
    updateUnion({ postalCode: postalCode })
  }

  const handleFaculties = (faculties) => {
    setSelectedFaculties(faculties)
    setFacultiesVisible(false)
  }

  return (
    <ScrollView keyboardShouldPersistTaps={'handled'}>
      <View style={styles.container} key={uniqueValue}>
        {props.renderChips()}
        <View style={styles.marginLeftRight}>
          <View style={styles.imagePickerContainer}>
            <ImagePicker
              editMode
              setImage={setImage}
              defaultImage={union?.image?.image?.uri ?? null}
              onDelete={() => {}}
              placeholder={imageBobl}
            />
          </View>

          <View style={styles.marginInput}>
            <CustomInputs
              accessibilityLabel={txt('unions.create.title')}
              accessibilityHint={txt('unions.create.titleHint')}
              placeholder={txt('unions.create.title')}
              maxLength={35}
              value={union.title}
              onChangeText={(title) => updateUnion({ title })}
            />
            {union.titleTooShort && (
              <CustomText style={styles.errorMessage}>{txt('unions.create.titleTooShort')}</CustomText>
            )}
          </View>
          <View style={styles.marginInput}>
            <CustomMultilineInputs
              accessibilityLabel={txt('unions.create.description')}
              accessibilityHint={txt('unions.create.descriptionHint')}
              placeholder={txt('unions.create.description')}
              value={union.body}
              onChangeText={(value) => updateUnion({ body: value })}
            />
            {union.descriptionTooShort && (
              <CustomText style={styles.errorMessage}>{txt('unions.create.descriptionTooShort')}</CustomText>
            )}
          </View>
          <View style={styles.marginInput}>
            <CustomInputs
              accessibilityLabel={txt('unions.create.address')}
              accessibilityHint={txt('unions.create.addressHint')}
              placeholder={txt('unions.create.address')}
              value={union.address}
              onChangeText={handleAddressChange}
            />
            {!isAddressValid && (
              <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                {txt('unions.create.invalidAddress')}
              </CustomText>
            )}
            {suggestions.length > 0 && union.address !== '' && renderSuggestions()}
          </View>

          <View style={styles.inputRow}>
            <View style={styles.marginInputSplit}>
              <CustomInputs
                placeholder={txt('unions.create.postalCode')}
                value={union.postalCode}
                inputMode="numeric"
                maxLength={4}
                onChangeText={handlePostalCodeChange}
              />
              {!isPostalValid && (
                <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                  {txt('unions.create.invalidPostalCode')}
                </CustomText>
              )}
              {isMissingPostalCode && (
                <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                  {txt('unions.create.postalCodeRequired')}
                </CustomText>
              )}
            </View>
            <View style={[styles.marginInputSplit, { zIndex: -1 }]}>
              <CustomInputs
                placeholder={txt('unions.create.city')}
                value={union.city}
                onChangeText={(value) => updateUnion({ city: value })}
              />
            </View>
          </View>
          <View style={[styles.marginInput, { zIndex: -1 }]}>
            <CustomInputs
              accessibilityLabel={txt('unions.create.url')}
              accessibilityHint={txt('unions.create.urlHint')}
              placeholder={txt('unions.create.url')}
              value={union.url}
              onChangeText={(value) => updateUnion({ url: value })}
            />
          </View>
          {!universe && (
            <View style={[styles.marginInput, { zIndex: -1 }]}>
              <CustomText font="title" text={txt('unions.create.municipality')} style={styles.marginBottom} />
              <CustomText font="small" text={txt('unions.create.municipalityText')} style={styles.marginBottom} />
              {pickMunicipality()}
            </View>
          )}

          {universe?.id === 12 && faculties.length > 0 && (
            <View>
              <CustomPressable
                onPress={() => setFacultiesVisible(true)}
                style={{
                  backgroundColor: Colors.white,
                  borderRadius: 10,
                  ...ShadowStyles.shadowInputs,
                  padding: 10,
                  marginHorizontal: 10,
                  marginBottom: 12,
                }}>
                <CustomText font="title" text={selectedFaculties.size === 1
                  ? selectedFaculties.size + txt('unions.create.faculty.selected')
                  : selectedFaculties.size > 1
                    ? selectedFaculties.size + txt('unions.create.faculty.multipleSelected')
                    : txt('unions.create.faculty.select')} />
              </CustomPressable>
              <ChooseFacultyItem
                visible={facultiesVisible}
                close={() => setFacultiesVisible(false)}
                faculties={faculties}
                onPress={handleFaculties}
                required
              />
              {isMissingFaculties && (
                <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                  {txt('unions.create.faculty.invalid')}
                </CustomText>
              )}
            </View>
          )}

          {((union.titleTooShort && union.descriptionTooShort) || union.titleTooShort || union.descriptionTooShort) && (
            <CustomText style={[styles.errorMessage, styles.marginLeftRight]}>
              {txt('unions.create.unionNotCreated')}
            </CustomText>
          )}

          <View style={[styles.margin, styles.center]}>
            <RoundBtn title={txt('unions.create.createUnion')} onPress={createUnionFull} />
          </View>
          {!isUnionValid ? (
            <InvalidInputNoticeCentered>{txt('invalidNotice.invalidBooking')}</InvalidInputNoticeCentered>
          ) : null}
        </View>
      </View>
      <CustomSnackbar
        visible={unionWasNotCreated}
        onDismiss={() => setUnionWasNotCreated(false)}
        text={txt('createPost.error')}
        style={{ marginBottom: 60 }}
      />
      {keyboardSpacerShow()}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  center: {
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  errorMessage: {
    color: Colors.errorBackground,
    ...TypographyStyles.textSmallBold,
    marginBottom: 5,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginVertical: 12,
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 5,
    zIndex: -1,
  },
  margin: {
    marginBottom: 20,
  },
  marginBottom: { marginBottom: 5 },
  marginInput: {
    ...SpacingStyles.marginRightLeft10,
    marginBottom: 15,
  },
  marginInputSplit: {
    flex: 1,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  marginLeftRight: { marginHorizontal: 12 },
  picker: {
    backgroundColor: Colors.white,
    padding: Platform.OS === 'android' ? 0 : 10,
    borderRadius: 12,
    marginTop: 6,
    ...ShadowStyles.shadowInputs,
  },
  suggestion: {
    borderBottomColor: Colors.blackTransparent,
    padding: 10,
  },
  suggestionsContainer: {
    ...ShadowStyles.shadowInputs,
    backgroundColor: Colors.white,
    borderRadius: 12,
    position: 'absolute',
    top: 48,
    width: '100%',
    zIndex: 1,
  },
  textCenter: { textAlign: 'center' },
})
