import * as MediaLibrary from 'expo-media-library'
import { Linking, Platform } from 'react-native'
import Constants from 'expo-constants'
import * as IntentLauncher from 'expo-intent-launcher'

export const getLibraryPermission = async () => {
  const permission = await MediaLibrary.getPermissionsAsync()
  if (permission.granted) return permission

  if (permission.canAskAgain) {
    return await MediaLibrary.requestPermissionsAsync()
  }

  await openSystemSettings()

  return await MediaLibrary.requestPermissionsAsync()
}

export async function openSystemSettings() {
  if (Platform.OS === 'ios') {
    await Linking.openSettings()
  } else if (Platform.OS === 'android') {
    const pkg = __DEV__ ? 'host.exp.exponent' : Constants.expoConfig.android.package
    const activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS
    await IntentLauncher.startActivityAsync(activityAction, { data: 'package:' + pkg })
  }
}