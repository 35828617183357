import { StyleSheet, View } from 'react-native'
import NormalIcon from '../../../icons/NormalIcon'
import React, { useEffect, useState } from 'react'
import { txt } from '../../../../locales/i18n'
import { ShadowStyles, TypographyStyles } from '../../../../styles'
import { CustomText } from '../../../text/StyledText'
import Colors from '../../../../constants/Colors'
import moment from 'moment'
import 'moment/min/locales' //needs to be there to update locale to another countrycode.
import CustomPressable from "../../../pressables/CustomPressable"
import ModalSelectorCustom from "../../../modalSelectors/ModalSelectorCustom"
import Layout from "../../../../constants/Layout"

export default function RenderPreviousBookers({ data, onChange, selected, reset, resetTrigger }) {
	const [listData, setListData] = useState(data)
	moment.locale('da')

	useEffect(() => {
		const setComponent = (user, index) => (
			<View key={index}>
				<CustomText font="gotham-bold-small">{user?.username}</CustomText>
				<CustomText font="gotham-small" style={{color: Colors.inactive, fontSize: 14}}>
					{txt('videoChat.volunteer.create.latest') + moment(user?.lastSessionDate).format('ll') + ' • ' + txt('videoChat.volunteer.create.count') + user?.sessionCount}
				</CustomText>
			</View>
		)

		if (data?.length > 0) {
			const newArr = data.map((c, index) => ({...c, component: setComponent(c, index)}))
			setListData(newArr)
		}
	}, [data])

	return (
		<View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1}}>
			<View style={{ flex: 9 }}>
				<ModalSelectorCustom
					data={listData}
					keyExtractor={(booker) => (booker.userId ? booker.userId?.toString() : booker.id?.toString())}
					labelExtractor={(booker) => (booker.username)}
					initValue={selected || txt('videoChat.volunteer.create.chooseFromPrev')}
					onChange={onChange}
					resetTrigger={resetTrigger}
					backdropPressToClose={true}
					animationType="fade"
					cancelText={txt('signupField.close')}
					style={styles.modalCustom}
					initValueTextStyle={[styles.textSmall, styles.selectColor, styles.alignLeft]}
					selectStyle={{ borderColor: Colors.transparent }}
					selectTextStyle={[styles.textSmall, styles.selectColor]}
					optionTextStyle={[styles.textSmall, styles.selectColor]}
					optionContainerStyle={styles.optionContainer}
					cancelStyle={styles.optionContainer}
					cancelTextStyle={styles.textSmall}
					rightElement={selected !== '' ? () => (
						<CustomPressable onPress={reset}>
							<NormalIcon name="times" size={18} color={Colors.redCross.red} style={{ marginHorizontal: 12 }} />
						</CustomPressable>
					) : null}
				/>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	modalCustom: {
		backgroundColor: Colors.white,
		// borderRadius: 10,

		paddingVertical: 2,
		borderWidth: 1,
		borderColor: Colors.redCross.border,
		...ShadowStyles.unsetShadows,
		borderRadius: 6,
	},
	optionContainer: {
		borderRadius: 10,
		backgroundColor: Colors.white,
		maxHeight: 500,
		maxWidth: Layout.maxFeedWidth,
		width: '100%',
		alignSelf: 'center',
	},
	selectColor: {
		color: Colors.text,
	},
	textSmall: {
		...TypographyStyles.textSmall,
		color: Colors.text,
	},
})