import React from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { RoundSquareBtn } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import { CustomSnackbar } from '../../snackbars/Snackbar'
import { createPasswordToken } from '../../../services/api/User'
import { FormInput } from '../../text/StyledTextinputs'

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      emailWasSent: false,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <View style={styles.widthHeight}>
        <View
          style={[
            styles.cornersTop,
            styles.marginLeftRight,
            styles.colorsWhite,
          ]}
        >
          <CustomText style={styles.title}>
            {txt('passwordField.titleReset')}
          </CustomText>
          <View>
            <CustomText
              accessible
              style={[styles.margin, styles.smallTitle, styles.textCenter]}
            >
              {txt('passwordField.emailDescription')}
            </CustomText>
            <View style={styles.margin}>
              <FormInput
                accessibilityLabel={txt('profile.email')}
                accessibilityHint={txt('profile.emailHint')}
                style={[styles.text, styles.marginLeftRight]}
                inputMode="email"
                value={this.state.email}
                onChangeText={(text) => {
                  this.setState({
                    email: text,
                  })
                }}
              />
            </View>
            <View style={styles.margin}>
              <RoundSquareBtn
                title={txt('passwordField.sendEmail')}
                onPress={() => {
                  if (this.state.email.trim().length < 5) {
                    return
                  }
                  createPasswordToken(this.state.email)

                  this.setState({ email: '', emailWasSent: true })
                }}
              />
            </View>
          </View>
        </View>
        <CustomSnackbar
          visible={this.state.emailWasSent}
          onDismiss={() => this.setState({ emailWasSent: false })}
          text={txt('passwordField.emailWasSent')}
          duration={3000}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  colorsWhite: { backgroundColor: Colors.white },
  cornersTop: { borderRadius: 10, marginTop: 12, padding: 10 },
  margin: { marginBottom: 10, marginTop: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  smallTitle: { ...TypographyStyles.textSmall, color: Colors.inactive },
  text: { ...TypographyStyles.text },
  textCenter: { ...TypographyStyles.text, textAlign: 'center' },
  title: { ...TypographyStyles.title, textAlign: 'center' },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
