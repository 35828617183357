import React, { useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import * as ImagePicker from 'expo-image-picker'
import { useCameraPermissions } from "expo-camera"

import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'

import { openSystemSettings } from "../../utils/getPermissions"

import { WebcamCapture } from './WebcamCapture'
import { ButtonPrimary } from '../buttons/StyledButtons'
import CustomDialog, { ActionButton, ContentContainer } from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'

export default function ImagePickerPrivate(props) {
  const [webcamOn, setWebcamOn] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [, requestPermission] = useCameraPermissions()

  const pickImages = async (type) => {
    const permission = await getPermissionAsync(type)
    if (!permission) return

    const mediaTypes = ImagePicker.MediaTypeOptions.Images

    if (type === 'library') {
      // quality: 1 is necessary for it to work on android
      const options = { mediaTypes, allowsMultipleSelection: true, base64: true, quality: 1 }
      const result = await ImagePicker.launchImageLibraryAsync(options)

      if (result.canceled || result.assets.length === 0) return
      props.addImages(filterImages(result.assets))
    }

    if (type === 'camera') {
      const options = { mediaTypes, allowsEditing: true, aspect: [1, 1], base64: true }
      const result = await ImagePicker.launchCameraAsync(options)

      if (result.canceled || result.assets.length === 0) return
      props.addImages(result.assets)
    }
  }

  const getPermissionAsync = async (type) => {
    if (Platform.OS !== 'web') {
      const { granted, canAskAgain } = await requestPermission()
      if (granted === false) {
        if (canAskAgain === false) {
          return await openSystemSettings()
        }
      }
      return granted
    } else {
      if (type === 'camera') {
        setWebcamOn(true)
        return false
      }
    }
    return true
  }

  const filterImages = (images) => {
    return images.filter((image) => {
      const isImage = /^data:image/.test(image.uri)
      if (isImage || image.type === 'image') {
        setShowDialog(false)
        return true
      } else if (!image.cancelled && image.type !== 'image') {
        setShowDialog(true)
        return false
      }
    })
  }

  const handleCaptureImage = (captureImage) => {
    setWebcamOn(false)
    const image = { uri: captureImage }
    props.addImages([image])
  }

  return (
    <View>
      {webcamOn ? (
        <WebcamCapture
          captureImage={(captureImage) => handleCaptureImage(captureImage)}
        />
      ) : (
        props.multiple ? (
          <ButtonPrimary
            icon="images"
            stroke={'fal'}
            color={props.color || Colors.white}
            onPress={() => pickImages('library')}
            style={{ backgroundColor: props.backgroundColor || Colors.tintColor }}
            titleStyle={{ color: props.buttonText || Colors.white }}
          />
        ) : (
          <View style={styles.buttonContainer}>
            <ButtonPrimary
              title={txt('profile.addImage')}
              icon="images"
              onPress={() => pickImages('library')}
            />
            <ButtonPrimary
              title={txt('profile.takeImage')}
              icon="camera"
              onPress={() => pickImages('camera')}
            />
          </View>
        )
      )}
      <CustomDialog visible={showDialog} onDismiss={() => setShowDialog(false)}>
        <ContentContainer>
          <CustomText>{txt('groups.bulletin.formatError')}</CustomText>
        </ContentContainer>
        <ActionButton onPress={() => setShowDialog(false)}>
          {txt('groups.bulletin.ok')}
        </ActionButton>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: 12,
  },
})