import React, { useEffect, useRef } from 'react'
import Colors from '../../constants/Colors'
import { Animated, Platform, StyleSheet } from 'react-native'
import { ShadowStyles } from '../../styles'
import { CustomText } from "../text/StyledText"
import NormalIcon from "../icons/NormalIcon"
import CustomPressable from "../pressables/CustomPressable"

export function CustomSnackbar({ visible, onDismiss, icon = null, duration = 4000, text, style }) {
  const fadeAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (visible) {
      Animated.timing(fadeAnim, { toValue: 1, duration: 300, useNativeDriver: true }).start()
      const timer = setTimeout(() => handleDismiss(), duration)
      return () => clearTimeout(timer)
    } else {
      handleDismiss()
    }
  }, [visible])

  const handleDismiss = () => {
    Animated.timing(fadeAnim, { toValue: 0, duration: 300, useNativeDriver: true })
      .start(() => onDismiss())
  }

  return (
    <Animated.View style={[ styles.container, { opacity: fadeAnim, display: visible ? 'flex' : 'none' }, style]}>
      {icon && <NormalIcon name={icon} size={20} color={Colors.whiteTransparent} style={{ marginRight: 9 }} />}
      <CustomText font="small" style={styles.snackbarText}>{text}</CustomText>
      <CustomPressable onPress={handleDismiss}>
        <NormalIcon name="close" size={20} color={Colors.whiteTransparent} />
      </CustomPressable>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    position: 'absolute',
    bottom: 10,
    borderRadius: 12,
    ...ShadowStyles.dropShadow,
    flexDirection: 'row',
    width: '95%',
    maxWidth: 400,
    alignSelf: 'center',
    backgroundColor: Colors.text,
  },
  snackbarText: {
    color: Colors.white,
    marginLeft: 3,
    marginRight: 12,
    flex: 10,
	paddingTop: Platform.OS !== 'web' ? 2 : 0,
  }
})
