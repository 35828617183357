import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import moment from 'moment'

import {
	addToJuniorWaitList,
	addToWaitList,
	getAvailable,
	getJuniorWaitListStatus,
	getSessionCount,
	getVolunteerProfile,
	getWaitListStatus,
} from '../../../services/api/LearnDanish'
import { surveyIds } from '../../../services/api/Surveys'

import SproglandJuniorWaitListDialog, { SproglandWaitListDialog } from './SproglandWaitListDialog'
import SessionCategoryDialog from './dialogs/sessionCategoryDialog'
import SurveyPromptDialog, { platforms } from '../../dialogs/SurveyPromptDialog'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import { CustomSnackbar } from '../../snackbars/Snackbar'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../pressables/CustomPressable"
import NormalIcon from '../../icons/NormalIcon'
import TextLabel from './TextLabel'
import Colors from '../../../constants/Colors'
import { VolunteerProfileDialog } from './dialogs/VolunteerProfileDialog'

export function AvailableSessions({ availableDates, getAvailableDates, bookSession, getBookedSessions, juniorId }) {
	const [sessionCount, setSessionCount] = useState(0)
	const [isOnWaitlist, setIsOnWaitlist] = useState(false)
	const [waitlistVisible, setWaitlistVisible] = useState(false)
	const [isOnJuniorWaitlist, setIsOnJuniorWaitlist] = useState(false)
	const [juniorWaitlistVisible, setJuniorWaitlistVisible] = useState(false)
	const [surveyPromptVisible, setSurveyPromptVisible] = useState(false)
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [data, setData] = useState([])
	const [sessionCategoryDialogVisible, setSessionCategoryDialogVisible] = useState(false)
	const [clickedCategoryId, setClickedCategoryId] = useState(null)
	const [volunteerProfile, setVolunteerProfile] = useState(null)
	const [volunteerProfileVisible, setVolunteerProfileVisible] = useState(false)

	useEffect(() => {
		moment.locale([txt('global.locale')])

		getWaitListStatus().then(setIsOnWaitlist)
		getJuniorWaitListStatus().then(setIsOnJuniorWaitlist)
		getSessionCount().then(setSessionCount)
	}, [])

	useEffect(() => {
		setData(availableDates)
	}, [availableDates])

	async function toggleDropDown(key) {
		const newData = [...data]

		// Update dropdownactive toggle state
		newData[key].dropdownActive = !newData[key].dropdownActive
		if (newData[key].dropdownActive === false) return setData(newData)

		// Fetch available sessions for the selected date
		const startDate = new Date(newData[key].date)
		const endDate = new Date(startDate.getTime() + 60 * 60 * 24 * 1000)

		const availableSessions = await getAvailable(startDate.getTime(), endDate.getTime(), Boolean(juniorId))
		newData[key].availableSessions = availableSessions

		setData(newData)
	}

	function formatDate(date, formatString) {
		const formattedDate = moment(date).format(formatString)
		return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
	}

	async function handleBookSession(sessionId) {
		const { bookingErrors } = txt('learnDanish')

		const ERR_ENUMS = {
			LIMIT_REACHED: txt('learnDanish.bookingErrors.limitReached'),
			SESSION_NOT_FOUND: txt('learnDanish.bookingErrors.SessionNotFound'),
			SESSION_ALREADY_BOOKED: txt('learnDanish.bookingErrors.alreadyBooked'),
			OWN_BOOKING: txt('learnDanish.bookingErrors.ownBooking'),
			DOUBLE_BOOKING: txt('learnDanish.bookingErrors.doubleBook'),
			DATE_EXPIRED: txt('learnDanish.bookingErrors.dateExpired'),
			TIMEFRAME_OCCUPIED: txt('learnDanish.bookingErrors.timeframeOccupied'),
			VOLUNTEER_IS_ALREADY_IN_TIMEFRAME: txt('learnDanish.bookingErrors.volunteerInTimeframe'),
			VOLUNTEER_LIMIT_REACHED: txt('learnDanish.bookingErrors.volunteerLimitReached'),
		}

		const { err_code } = await bookSession(sessionId)

		if (err_code !== undefined) {
			const errorMessageText = ERR_ENUMS[err_code] ?? bookingErrors.unknown
			setShowSnackbar(true)
			return setErrorMessage(errorMessageText)
		}

		getBookedSessions()
		getAvailableDates()
	}

	async function onJuniorWaitlistPress() {
		const didUpdate = await addToJuniorWaitList()
		if (didUpdate) {
			setJuniorWaitlistVisible(false)
			setIsOnJuniorWaitlist(true)
		}
	}

	async function onWaitlistPress() {
		const didUpdate = await addToWaitList()
		if (didUpdate) {
			setWaitlistVisible(false)
			setIsOnWaitlist(true)
		}
	}

	function handleCategoryPress(categoryId) {
		setClickedCategoryId(categoryId)
		setSessionCategoryDialogVisible(true)
	}

	async function handlePressVolunteer(volunteerId, volunteerName) {
		if (!volunteerId) return
		const volunteerProfile = await getVolunteerProfile(volunteerId)
		setVolunteerProfile({ description: volunteerProfile?.description, volunteerName })
		setVolunteerProfileVisible(true)
	}

	function noSessionsText() {
		if ((isOnWaitlist && !juniorId) || (isOnJuniorWaitlist && juniorId)) return txt('learnDanish.waitlist.alreadyOnWaitlist')
		if (juniorId) return txt('learnDanish.noAvailableJuniorSessions')
		return txt('learnDanish.noAvailableSessions')
	}

	return (
		<View style={styles.container}>
			{availableDates.length === 0 ? (
				<View>
					<CustomText font="largeBold" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
						{juniorId ?
							txt('learnDanish.waitlist.title') :
							txt('videoChat.availableSessions')
						}
					</CustomText>
					<CustomText font="small" style={styles.noSession}>
						{noSessionsText()}
					</CustomText>
					{juniorId && !isOnJuniorWaitlist && (
						<ButtonPrimary
							title={txt('learnDanish.junior.waitlist.add')}
							accessibilityLabel={txt('learnDanish.junior.waitlist.add')}
							onPress={() => setJuniorWaitlistVisible(true)}
							titleStyle={{ ...TypographyStyles.textSmallBold }}
							style={[styles.button, { marginTop: 24 }]}
						/>
					)}
					{/* {sessionCount < 1 && !juniorId && !isOnWaitList && (
						<ButtonPrimary
							title={txt('learnDanish.waitlist.add')}
							accessibilityLabel={txt('learnDanish.waitlist.add')}
							onPress={() => setWaitlistVisible(true)}
							titleStyle={{ ...TypographyStyles.textSmallBold }}
							style={[styles.button, { marginTop: 24 }]}
						/>
					)} */}
				</View>
			) : (
				<View>
					<CustomText font="largeBold" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
						{txt('videoChat.availableSessions')}
					</CustomText>
				</View>
			)}
			{data.map((item, key) => {
				if (item.dropdownActive) {
					let itemArray
					if (item.availableSessions.length !== 0) {
						// Render each date
						itemArray = item.availableSessions.map((session, index) => {
							// Create smaller marginTop on (index == 0), and borderTop on (index > 0) (for visual purposes)
							const sessionBoxProps = index === 0 ? { marginTop: 10 } : styles.sessionBoxProps
							return (
								<View key={session.id} style={[styles.flexRow, { ...sessionBoxProps }]}>
									<View style={{ flex: 1 }}>
										<CustomText font="small">{formatDate(session.start_date, 'dddd [d.] DD/MM [kl.] HH:mm')}</CustomText>
										<CustomText
											font="small"
											text={
												txt('videoChat.duration') + ': ' +
												(new Date(session.end_date).getTime() - new Date(session.start_date).getTime())
												/ 1000 / 60 + ' min.'
											}
										/>
										<CustomPressable onPress={() => handlePressVolunteer(session.volunteerId, session.username)}>
											<CustomText
												font="small"
												text={txt('learnDanish.volunteer.title') + ': ' + session.username}
												numberOfLines={1}
												style={{ flex: 1, flexWrap: 'wrap', textDecorationLine: 'underline' }}
											/>
										</CustomPressable>	
										<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
											{session.categories?.map((id, key) => (
												<CustomPressable
													onPress={() => handleCategoryPress(id)}
													accessibilityRole="button"
													key={key}
												>
													<TextLabel
														key={id}
														text={txt(`learnDanish.categories.${id}`)}
														style={{ marginRight: 6, marginTop: 6 }}
														showInfoIcon
													/>
												</CustomPressable>
											))}
										</View>
									</View>

									<ButtonSecondary
										title={txt('videoChat.book')}
										titleStyle={{ ...TypographyStyles.textSmallBold }}
										style={[styles.button, { borderWidth: 1 }]}
										onPress={() => handleBookSession(session.id)}
									/>
									<CustomSnackbar
										visible={showSnackbar}
										onDismiss={() => setShowSnackbar(false)}
										text={errorMessage}
										duration={5000}
									/>
								</View>
							)
						})
					}

					return (
						// Expanded view
						<View key={key} style={styles.dropdownContainer}>
							<CustomPressable
								onPress={() => toggleDropDown(key)}
								activeOpacity={0.8}
								accessibilityRole="button"
								accessibilityLabel={txt('videoChat.availableSessionsHideLabel')}
							>
								<View style={styles.flexRow}>
									<CustomText>{formatDate(item.date, 'dddd[,] DD[.] MMMM YYYY')}</CustomText>
									<NormalIcon
										name="chevron-up"
										color={Colors.inactive}
										style={styles.icon}
										size={18}
										stroke="fas"
									/>
								</View>
							</CustomPressable>
							{itemArray}
						</View>
					)
				}

				return (
					// Collapsed view
					<CustomPressable
						key={key}
						onPress={() => toggleDropDown(key)}
						activeOpacity={0.8}
						style={styles.dropdownContainer}
						accessibilityRole="button"
						accessibilityLabel={txt('videoChat.availableSessionsLabel')}
					>
						<View style={styles.flexRow}>
							<CustomText>{formatDate(item.date, 'dddd[,] DD[.] MMMM YYYY')}</CustomText>
							<NormalIcon
								name="chevron-down"
								color={Colors.inactive}
								style={styles.icon}
								size={18}
								stroke="fas"
							/>
						</View>
					</CustomPressable>
				)
			})}
			<SurveyPromptDialog
				visible={surveyPromptVisible}
				surveyId={surveyIds.sproglandUser}
				platform={platforms.Sprogland}
				surveyLink={'https://survey.rodekors.dk/LinkCollector?key=T57VRJ3MJ636'}
				onDismiss={() => setSurveyPromptVisible(false)}
			/>
			<SproglandJuniorWaitListDialog
				visible={juniorWaitlistVisible}
				onDismiss={() => setJuniorWaitlistVisible(false)}
				submit={() => onJuniorWaitlistPress()}
			/>
			<SproglandWaitListDialog
				visible={waitlistVisible}
				onDismiss={() => setWaitlistVisible(false)}
				submit={() => onWaitlistPress()}
			/>
			<SessionCategoryDialog
				visible={sessionCategoryDialogVisible}
				onDismiss={() => setSessionCategoryDialogVisible(false)}
				categoryId={clickedCategoryId}
			/>
			<VolunteerProfileDialog
				visible={volunteerProfileVisible}
				onDismiss={() => setVolunteerProfileVisible(false)}
				username={volunteerProfile?.volunteerName}
				description={volunteerProfile?.description}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	button: {
		width: 150,
		paddingHorizontal: 8,
	},
	container: {
		margin: 10,
	},
	dropdownContainer: {
		backgroundColor: Colors.white,
		borderRadius: 2,
		marginTop: 10,
		padding: 10,
		...ShadowStyles.liftedShadow,
	},
	flexRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		alignSelf: 'center',
		marginLeft: 'auto'
	},
	noSession: {
		color: Colors.inactive,
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: Colors.white,
		padding: 10,
		borderRadius: 2,
		...ShadowStyles.liftedShadow,
	},
	titleBold: {
		textAlign: 'center',
	},
	sessionBoxProps: {
		borderTopWidth: 1,
		borderColor: Colors.shadow,
		marginTop: 10,
		paddingTop: 10,
	},
})
