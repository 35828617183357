import React, { useState } from "react"
import { StyleSheet } from "react-native"

import { reportUser } from "../../../../services/api/snakSammen"

import CustomPressable from "../../../pressables/CustomPressable"
import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import { CustomText } from "../../../text/StyledText"

import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"
import { ButtonPrimary } from "../../../buttons/StyledButtons"

export default function ReportSproglandUserDialog({ visible, onDismiss, currentItem, reloadAll }) {
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	if (!currentItem) return

	async function handleReportUser() {
		const userId = currentItem.userId ?? currentItem.bookerId
		const res = await reportUser(userId)
		reloadAll?.()
		if (res) return setSuccess(true)
		setError(true)
	}

	return (
		<CustomDialog visible={visible} onDismiss={onDismiss}>
			<CustomPressable onPress={onDismiss} style={styles.closeIcon}>
				<NormalIcon name="times" size={20} color={Colors.darkGrey} />
			</CustomPressable>
			<ContentContainer>
				<CustomText font="gotham-bold" style={{ marginBottom: 12 }}>
					{'Rapporter deltager'}
				</CustomText>

				{!success && (
					<CustomText style={{ marginBottom: 20 }}>
						{`Vil du rapportere at ${currentItem.username} bruger SnakSammen til sprogtræning?`}
					</CustomText>
				)}

				{error && (
					<CustomText style={styles.errorText}>
						{'Der skete en fejl. Prøv igen senere.'}
					</CustomText>
				)}

				{success ? (
					<>
						<CustomText style={{ color: Colors.green, marginBottom: 12 }}>
							{`Deltageren ${currentItem.username} er blevet rapporteret.`}
						</CustomText>
						<CustomText>
							{`Vi anbefaler, at du skriver en besked til ${currentItem.username} og henviser til Sprogland for sprogtræning.`}
						</CustomText>
					</>
				) : (
					<ButtonPrimary
						onPress={handleReportUser}
						style={styles.button}
						titleStyle={styles.buttonTitle}
						title={'Rapporter'}
					/>
				)}
			</ContentContainer>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 1,
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		minWidth: 115,
	},
	buttonTitle: {
		color: Colors.redCross.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
	errorText: {
		color: Colors.redCross.red,
		marginBottom: 20
	},
})
