import { StyleSheet, View } from 'react-native'
import NormalIcon from '../../../icons/NormalIcon'
import React, { useEffect, useState } from 'react'
import { txt } from '../../../../locales/i18n'
import { TypographyStyles } from '../../../../styles'
import moment from 'moment'
import 'moment/min/locales' //needs to be there to update locale to another countrycode.
import { CustomText } from "../../../text/StyledText"
import Colors from "../../../../constants/Colors"
import CustomPressable from "../../../pressables/CustomPressable"
import ModalSelectorCustom from "../../../modalSelectors/ModalSelectorCustom"

export default function RenderPreviousBookers({ data, onChange, selected, reset, resetTrigger }) {
  const [listData, setListData] = useState(data)
  moment.locale('da')

  useEffect(() => {
    const setComponent = (user, index) => (
      <View key={index}>
        <CustomText font="smallBold">{user?.username}</CustomText>
        <CustomText style={{color: Colors.inactive, fontSize: 14}}>
          {txt('learnDanish.volunteer.create.latest') + moment(user?.lastSessionDate).format('ll') + ' • ' + txt('learnDanish.volunteer.create.count') + user?.sessionCount}
        </CustomText>
      </View>
    )

    const setJuniorComponent = (user, index) => {
      const juniors = JSON.parse(user?.juniors)
      let juniorList = juniors?.map((junior) => junior?.name).join(', ')
      return (
        <View key={index}>
          <CustomText font="smallBold">{juniorList}</CustomText>
          <CustomText style={{color: Colors.inactive, fontSize: 14}}>{user?.username}</CustomText>
          <CustomText style={{color: Colors.inactive, fontSize: 14}}>
            {txt('learnDanish.volunteer.create.latest') + moment(user?.lastSessionDate).format('ll') + ' • ' + txt('learnDanish.volunteer.create.count') + user?.sessionCount}
          </CustomText>
        </View>
      )
    }
    if (data?.length > 0) {
      const newArr = data.map((c, index) => ({...c, component: c?.juniors? setJuniorComponent(c, index) : setComponent(c, index)}))
      setListData(newArr)
    }
  }, [data])

  function setJuniors(booker) {
    const juniors = JSON.parse(booker.juniors)
    let juniorList = juniors.map((junior) => junior.name).join(', ')
    return `${juniorList}` + `\n(${booker.username})`
  }

  return (
    <View style={{ marginVertical: 12, flexDirection: 'row', alignItems: 'center' }}>
      <View style={{ flex: 9 }}>
        <ModalSelectorCustom
          data={listData}
          keyExtractor={(booker) => (booker.user_id ? booker.user_id?.toString() : booker.id?.toString())}
          labelExtractor={(booker) => (booker.juniors ? setJuniors(booker) : booker.username)}
          onChange={onChange}
          resetTrigger={resetTrigger}
          initValue={selected || txt('learnDanish.volunteer.create.chooseFromPrev')}
          backdropPressToClose={true}
          animationType="fade"
          cancelText={txt('signupField.close')}
          initValueTextStyle={[styles.textSmall, styles.selectColor]}
          selectTextStyle={[styles.textSmall, styles.selectColor]}
          optionTextStyle={[styles.textSmall, styles.selectColor]}
          cancelTextStyle={styles.textSmall}
          style={{ paddingVertical: 2 }}
          selectStyle={{ borderColor: Colors.transparent }}
          rightElement={selected !== '' ? () => (
            <CustomPressable onPress={reset}>
              <NormalIcon name="times" size={18} color={Colors.redCross.red} style={{ marginHorizontal: 12 }} />
            </CustomPressable>
          ) : null}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  selectColor: {
    color: Colors.text,
  },
  textSmall: {
    ...TypographyStyles.textSmall,
    color: Colors.text,
  },
})