import React, { useEffect, useRef } from 'react'
import Colors, { getColorFromPercent } from '../../constants/Colors'
import { Animated, StyleSheet, View } from 'react-native'
import { ShadowStyles } from '../../styles'

export const CustomProgressBar = ({ progress, style }) => {
  const animatedWidth = useRef(new Animated.Value(0)).current

  useEffect(() => {
    const progressWidth = Math.min(Math.max(progress, 0), 1) * 100
    Animated.timing(animatedWidth, {
      toValue: progressWidth,
      duration: 500,
      useNativeDriver: false
    }).start()
  }, [progress])

  return (
    <View style={[styles.container, style]}>
      <Animated.View style={[styles.progressBar, {
        width: animatedWidth.interpolate({
          inputRange: [0, 100],
          outputRange: ['0%', '100%']
        }), backgroundColor: getColorFromPercent(progress * 100)
      }]} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 10,
    width: '100%',
    backgroundColor: Colors.white,
    borderRadius: 5,
    overflow: 'hidden',
    ...ShadowStyles.shadowInputs,
  },
  progressBar: {
    height: '100%',
    borderRadius: 5
  },
})
