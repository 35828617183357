// libraries
import React, { useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/core'

// api
import { closeGroup, getJoinedGroupsList, updateGroup } from '../../../services/api/Groups'
import { UploadImage } from '../../../services/api/UploadImage'

// components
import { CustomText } from '../../text/StyledText'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { CustomSnackbar } from '../../snackbars/Snackbar'
import { RadioButton } from '../../formElements/formElements'
import { groupThemes } from './themes/GroupThemes'
import GroupDialog from './dialogs/GroupDialog'
import ImagePicker from '../../ImagePicker/ImagePicker'
import ThemeRadioButton from './themes/ThemeRadioButton'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'

import { txt } from '../../../locales/i18n'
import Paths from '../../../constants/Paths'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import imageBobl from '../../../assets/images/bobl.png'

export default function GroupSettings(props) {
  const [groupSettingsState, setGroupSettingsState] = useState({
    group: {
      id: null,
      name: '',
      description: '',
      img_id: null,
      status_id: null,
      type: null,
      password: '',
    },
  })
  const [loading, setLoading] = useState(true)
  const [settingsSaved, setSettingsSaved] = useState(false)
  const [settingsDidNotSave, setSettingsDidNotSave] = useState(false)
  const [groupPasswordMissing, setGroupPasswordMissing] = useState(false)
  const [theme, setTheme] = useState({})
  const [image, setImage] = useState(null)
  const [closeDialogVisible, setCloseDialogVisible] = useState(false)
  const navigation = useNavigation()

  function updateState(update) {
    setGroupSettingsState((prev) => ({
      ...prev,
      ...update,
    }))
  }

  useEffect(() => {
    if (props.group) {
      setGroup(props.group)
      setTheme(props.group.theme)
      setLoading(false)
    }
  }, [props])

  function setGroup(update) {
    updateState({
      group: { ...groupSettingsState.group, ...update },
    })
  }

  const uploadImg = () => {
    if (image) {
      return UploadImage(image, 0, 'Groups').catch(
        (err) => {
          if (__DEV__) console.log('IMAGE UPLOAD FAIL ', err)
        }
      )
    }
  }

  function validateGroup() {
    if (!groupSettingsState.group.type === 2) {
      setGroup({ password: '' })
    }

    if (groupSettingsState.group.type === 2 && groupSettingsState.group.password < 1) {
      setGroupPasswordMissing(true)
      return false
    }
    return true
  }

  const saveGroup = async () => {
    if (validateGroup()) {
      const img_id = await uploadImg()

      const data = {
        id: groupSettingsState.group.id,
        name: groupSettingsState.group.name,
        description: groupSettingsState.group.description,
        img_id: img_id || groupSettingsState?.group?.img_id,
        status_id: groupSettingsState.group.status_id,
        type: groupSettingsState.group.type,
        password: groupSettingsState.group.type === 2 ? groupSettingsState.group.password : '',
      }
      data.theme = JSON.stringify(theme)
      const res = await updateGroup(data)

      if (res.data) {
        setSettingsSaved(true)
        setGroupPasswordMissing(false)
        props.setTheme(res.data.theme)

        const group = {
          name: res.data.name,
          img_id: res.data.img_id,
          status_id: res.data.status_id,
          description: res.data.description,
          img_filename: res.data.img_filename,
          type: groupSettingsState.group.type,
          password: groupSettingsState.group.password
        }

        setGroup(group)
        props.setGroup((previous) => ({ ...previous, ...group }))
      } else setSettingsDidNotSave(true)
    }
  }

  if (loading || !groupSettingsState.group) return <LoadingSpinner />

  const changeTheme = (themeId) => {
    const theme = groupThemes.find((theme) => theme.id === themeId)
    setTheme(theme || null)
  }

  async function handleCloseGroup() {
    const res = await closeGroup(props.group.id)
    await getJoinedGroupsList()
    if (res.ok) navigation.replace('Groups')
  }

  return (
    <ScrollView style={props.style}>
      <View style={styles.container}>
        <View style={styles.marginBottom}>
          <CustomText font={'bold'}>{txt('groups.settings.groupImage')}</CustomText>
        </View>

        <View style={styles.imagePickerContainer}>
          <ImagePicker
            editMode
            setImage={setImage}
            defaultImage={
              groupSettingsState?.group?.img_filename
                ? Paths.groupUrlBase + groupSettingsState.group.img_filename
                : null
            }
            onDelete={() => setGroup({ img_id: null, img_filename: null })}
            placeholder={imageBobl}
            hideDelete
          />
        </View>

        <View style={styles.marginBottom}>
          <CustomText font={'bold'}>{txt('groups.settings.groupName')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          <View style={styles.marginInput}>
            <CustomInputs
              value={groupSettingsState.group.name}
              accessibilityLabel={txt('groups.settings.groupName')}
              accessibilityHint={txt('groups.settings.groupNameHint')}
              onChangeText={(value) => setGroup({ name: value })}
              style={{ backgroundColor: props.group.theme?.secondary || Colors.white }}
            />
          </View>
        </View>
        <View style={styles.marginBottom}>
          <CustomText font={'bold'}>{txt('groups.settings.groupDescription')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          <View style={styles.marginInput}>
            <CustomMultilineInputs
              accessibilityLabel={txt('groups.settings.groupDescription')}
              accessibilityHint={txt('groups.settings.groupDescriptionHint')}
              value={groupSettingsState.group.description}
              onChangeText={(value) => setGroup({ description: value })}
              style={{ backgroundColor: props.group.theme?.secondary || Colors.white }}
            />
          </View>
        </View>

        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.groupType')}</CustomText>
        </View>

        {/* Public group */}
        <View style={[styles.row, styles.marginBottom]}>
          <RadioButton
            checked={groupSettingsState.group.type === 4}
            onPress={() => setGroup({ type: 4 })}
            text={txt('groups.addGroup.openGroupOption')}
            color={props.group.theme?.primary || Colors.tintColor}
          />
        </View>
        <View style={styles.marginBottom}>
          {/* Request group */}
          <View style={styles.row}>
            <RadioButton
              checked={groupSettingsState.group.type === 3}
              onPress={() => setGroup({ type: 3 })}
              text={txt('groups.addGroup.requestGroupOption')}
              color={props.group.theme?.primary || Colors.tintColor}
            />
          </View>
        </View>
        <View style={styles.marginBottom}>
          {/* Password group */}
          <View style={styles.row}>
            <RadioButton
              checked={groupSettingsState.group.type === 2}
              onPress={() => setGroup({ type: 2 })}
              text={txt('groups.addGroup.passwordGroupOption')}
              color={props.group.theme?.primary || Colors.tintColor}
            />
          </View>
          {groupSettingsState.group.type === 2 && groupPasswordMissing && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.missingGroupPassword')}
            </CustomText>
          )}
          {groupSettingsState.group.type === 2 && (
            <View style={[styles.marginBottom, { marginTop: 5 }]}>
              <CustomInputs
                placeholder={txt('groups.addGroup.passwordPlaceholder')}
                accessibilityLabel={txt('groups.addGroup.passwordPlaceholder')}
                accessibilityHint={txt('groups.addGroup.passwordHint')}
                value={groupSettingsState.group.password}
                onChangeText={(value) => setGroup({ password: value })}
                style={{ backgroundColor: props.group.theme?.secondary || Colors.white }}
              />
            </View>
          )}
        </View>
        <View style={styles.marginBottom}>
          {/* Hidden group */}
          <View style={[styles.row, styles.marginBottom]}>
            <RadioButton
              checked={groupSettingsState.group.type === 1}
              onPress={() => setGroup({ type: 1 })}
              text={txt('groups.settings.hidden')}
              color={props.group.theme?.primary || Colors.tintColor}
            />
          </View>
          <View style={styles.marginBottom}>
            <CustomText style={styles.smallText}>{txt('groups.settings.hiddenText')}</CustomText>
          </View>
        </View>

        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.chooseTheme')}</CustomText>

          <View
            style={[{
              backgroundColor: props.group.theme?.secondary || Colors.white,
            }, styles.themeContainer]}>
            <ThemeRadioButton
              checked={theme?.id === 0 || theme === null}
              onPress={() => changeTheme(0)}
              color={groupThemes[0].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 1}
              onPress={() => changeTheme(1)}
              color={groupThemes[1].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 2}
              onPress={() => changeTheme(2)}
              color={groupThemes[2].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 3}
              onPress={() => changeTheme(3)}
              color={groupThemes[3].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 4}
              onPress={() => changeTheme(4)}
              color={groupThemes[4].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 5}
              onPress={() => changeTheme(5)}
              color={groupThemes[5].primary}
            />
            <ThemeRadioButton
              checked={theme?.id === 6}
              onPress={() => changeTheme(6)}
              color={groupThemes[6].primary}
            />
          </View>
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
          <ButtonPrimary
            onPress={() => setCloseDialogVisible(true)}
            style={{ backgroundColor: Colors.red }}
            titleStyle={{ color: Colors.white }}
            color={Colors.white}
            icon="trash"
            stroke={'fal'}
            title={txt('groups.settings.close')}
          />
          <ButtonPrimary
            onPress={saveGroup}
            style={{ backgroundColor: props.group.theme?.primary || Colors.tintColor }}
            titleStyle={{ color: props.group.theme?.buttonText || Colors.white }}
            color={props.group.theme?.buttonText || Colors.white}
            icon="save"
            stroke={'fal'}
            title={txt('groups.settings.save')}
          />
        </View>
      </View>

      <CustomSnackbar
        visible={settingsSaved}
        onDismiss={() => setSettingsSaved(false)}
        text={txt('post.savePostSnackBar')}
      />

      <CustomSnackbar
        visible={settingsDidNotSave}
        onDismiss={() => setSettingsDidNotSave(false)}
        text={txt('post.savePostSnackBarFailed')}
      />

      <GroupDialog
        visible={closeDialogVisible}
        title={txt('groups.settings.closeTitle')}
        description={txt('groups.settings.closeDescription')}
        accept={handleCloseGroup}
        acceptText={txt('groups.settings.close')}
        cancel={() => setCloseDialogVisible(false)}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginBottom: 30,
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
    paddingHorizontal: 10,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  marginBottom: { marginBottom: 10 },
  marginInput: { marginBottom: 10 },
  row: { alignItems: 'center', flexDirection: 'row' },
  smallText: { ...TypographyStyles.textSmallBold, color: Colors.inactive },
  title: { ...TypographyStyles.textBold },
  errorMessage: {
    color: Colors.errorBackground,
    ...TypographyStyles.textSmallBold,
    marginBottom: 5,
  },
  themeContainer: {
    padding: 12,
    marginVertical: 12,
    borderRadius: 10,
    ...ShadowStyles.cardShadow,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
})
