// From library react-native-modal-selector

'use strict'
import React, { useEffect, useState } from 'react'
import { View, Modal, FlatList, ScrollView, StyleSheet } from 'react-native'
import { CustomText } from "../text/StyledText"
import Colors from "../../constants/Colors"
import Layout from "../../constants/Layout"
import { txt } from "../../locales/i18n"
import CustomPressable from "../pressables/CustomPressable"
import { ShadowStyles } from "../../styles"

const PADDING = 10
const BORDER_RADIUS = 12
const FONT_SIZE = 17

const defaultProps = () => ({
	data: [],
	onChange: item => {},
	onModalOpen: () => {},
	onModalClose: () => {},
	keyExtractor: (item) => item && item.key,
	labelExtractor: (item) => item && item.label,
	componentExtractor: (item) => item && item.component,
	listType: 'SCROLLVIEW',
	visible: false,
	closeOnChange: true,
	initValue: txt('global.select'),
	animationType: 'fade',
	style: {},
	selectStyle: {},
	selectTextStyle: {},
	optionStyle: {},
	optionTextStyle: {},
	optionContainerStyle: {},
	sectionStyle: {},
	childrenContainerStyle: {},
	touchableStyle: {},
	touchableActiveOpacity: 0.2,
	sectionTextStyle: {},
	selectedItemTextStyle: {},
	cancelContainerStyle: {},
	cancelStyle: {},
	cancelTextStyle: {},
	overlayStyle: {},
	initValueTextStyle: {},
	cancelText: txt('global.close'),
	disabled: false,
	supportedOrientations: ['portrait', 'landscape'],
	keyboardShouldPersistTaps: 'always',
	backdropPressToClose: true,
	openButtonContainerAccessible: false,
	listItemAccessible: false,
	cancelButtonAccessible: false,
	scrollViewAccessible: false,
	scrollViewAccessibilityLabel: "",
	cancelButtonAccessibilityLabel: "",
	passThruProps: {},
	selectTextPassThruProps: {},
	optionTextPassThruProps: {},
	cancelTextPassThruProps: {},
	scrollViewPassThruProps: {},
	modalOpenerHitSlop: { top: 0, bottom: 0, left: 0, right: 0 },
	customSelector: undefined,
	selectedKey: '',
	optionsTestIDPrefix: 'default',
	header: undefined,
	onEndReached: undefined,
	rightElement: undefined,
	resetTrigger: undefined,
})

export default function ModalSelectorCustom(props) {

	const importedProps = {...defaultProps(), ...props}

	const selectedItem = validateSelectedKey(importedProps.selectedKey)
	const [modalVisible, setModalVisible] = useState(false) // Initialize as false
	const [selected, setSelected] = useState(selectedItem.label)
	const [changedItem, setChangedItem] = useState(selectedItem.key)
	const [hasInitialized, setHasInitialized] = useState(false) // Track initial render

	useEffect(() => {
		// Only set `modalVisible` after the first render
		if (!hasInitialized) {
			setHasInitialized(true)
			setModalVisible(importedProps.visible) // Set only once initially
		} else {
			setModalVisible(importedProps.visible)
		}
	}, [importedProps.visible])

	useEffect(() => {
		if (importedProps.resetTrigger) {
			setSelected(importedProps.initValue)
			setChangedItem(undefined)
		}
	}, [importedProps.resetTrigger])

	useEffect(() => {
		let newState = {}
		let doUpdate = false
		if (importedProps.initValue !== selected) {
			newState.selected = importedProps.initValue
			doUpdate = true
		}
		if (importedProps.selectedKey !== changedItem) {
			let selectedItem = validateSelectedKey(importedProps.selectedKey)
			newState.selected = selectedItem.label
			newState.changedItem = selectedItem.key
			doUpdate = true
		}
		if (doUpdate) {
			setSelected(newState.selected)
			setChangedItem(newState.changedItem)
		}
	}, [importedProps.initValue, importedProps.selectedKey, importedProps.data])

	function validateSelectedKey(key) {
		let selectedItem = importedProps.data.filter((item) => importedProps.keyExtractor(item) === key)
		let selectedLabel = selectedItem.length > 0 ? importedProps.labelExtractor(selectedItem[0]) : importedProps.initValue
		let selectedKey = selectedItem.length > 0 ? key : undefined
		return {label: selectedLabel, key: selectedKey}
	}

	const handleOnChange = (item) => {
		const key = importedProps.keyExtractor(item)
		if (!item || key == null) { // == coercion
			return
		}
		importedProps.onChange(item)
		setSelected(importedProps.labelExtractor(item))
		setChangedItem(key)
		if (importedProps.closeOnChange) {
			close()
		}
	}

	const close = (item) => {
		importedProps.onModalClose(item)
		setModalVisible(false)
	}

	const open = (params = {}) => {
		importedProps.onModalOpen(params)
		setModalVisible(true)
		setChangedItem(undefined)
	}

	const renderSection = (section) => {
		const optionComponent = importedProps.componentExtractor(section)
		let component = optionComponent || (
			<CustomText style={[styles.sectionTextStyle, importedProps.sectionTextStyle]}>{importedProps.labelExtractor(section)}</CustomText>
		)

		return (
			<View key={importedProps.keyExtractor(section)} style={[styles.sectionStyle, importedProps.sectionStyle]}>
				{component}
			</View>
		)
	}

	const renderOption = (option, isLastItem, isFirstItem) => {
		const optionComponent = importedProps.componentExtractor(option)
		const optionLabel = importedProps.labelExtractor(option)
		const isSelectedItem = optionLabel === selected

		let component = optionComponent || (
			<CustomText style={[styles.optionTextStyle, importedProps.optionTextStyle,isSelectedItem && importedProps.selectedItemTextStyle]} {...importedProps.optionTextPassThruProps}>
				{optionLabel}
			</CustomText>
		)

		return (
			<CustomPressable
				key={importedProps.keyExtractor(option)}
				testID={option.testID || importedProps.optionsTestIDPrefix + '-' + optionLabel}
				onPress={() => handleOnChange(option)}
				activeOpacity={importedProps.touchableActiveOpacity}
				accessible={importedProps.listItemAccessible}
				accessibilityLabel={option.accessibilityLabel || undefined}
				importantForAccessibility={isFirstItem ? 'yes' : 'no'}
				{...importedProps.passThruProps}
			>
				<View style={[styles.optionStyle, importedProps.optionStyle, isLastItem && {borderBottomWidth: 0}]}>
					{component}
				</View>
			</CustomPressable>)
	}

	const renderFlatlistOption = ({ item, index }) => {
		if (item.section) {
			return renderSection(item)
		}
		const numItems = importedProps.data.length
		return renderOption(item, index === (numItems - 1), index === 0)
	}

	// Data Picker
	const dataPicker = () =>  {
		let options = importedProps.data.map((item, index) => {
			if (item.section) {
				return renderSection(item)
			}
			return renderOption(item, index === importedProps.data.length - 1, index === 0)
		})

		const optionsContainerStyle = {paddingHorizontal: 10}
		if (importedProps.scrollViewPassThruProps && importedProps.scrollViewPassThruProps.horizontal) {
			optionsContainerStyle.flexDirection = 'row'
		}

		return (
			<Modal
				transparent={true}
				supportedOrientations={importedProps.supportedOrientations}
				visible={modalVisible}
				onRequestClose={close}
				animationType={importedProps.animationType}
				// onDismiss={() => changedItem && importedProps.onChange(changedItem)}
			>
				<CustomPressable onPress={close} style={{ flex: 1 }}>
					<View style={[styles.overlayStyle, importedProps.overlayStyle]} pointerEvents="box-none">
						<View style={[styles.optionContainer, importedProps.optionContainerStyle]}>
							{importedProps.header}
							{importedProps.listType === 'FLATLIST'?
								<FlatList
									data={importedProps.data}
									keyboardShouldPersistTaps={importedProps.keyboardShouldPersistTaps}
									accessible={importedProps.scrollViewAccessible}
									accessibilityLabel={importedProps.scrollViewAccessibilityLabel}
									keyExtractor={importedProps.keyExtractor}
									renderItem={renderFlatlistOption}
									onEndReached={importedProps.onEndReached}
								/>
								:
								<ScrollView
									keyboardShouldPersistTaps={importedProps.keyboardShouldPersistTaps}
									accessible={importedProps.scrollViewAccessible}
									accessibilityLabel={importedProps.scrollViewAccessibilityLabel}
									{...importedProps.scrollViewPassThruProps}
								>
									<View style={optionsContainerStyle}>
										{options}
									</View>
								</ScrollView>
							}
						</View>
						<View style={[styles.cancelContainer, importedProps.cancelContainerStyle]}>
							<CustomPressable
								onPress={close}
								activeOpacity={importedProps.touchableActiveOpacity}
								accessible={importedProps.cancelButtonAccessible}
								accessibilityLabel={importedProps.cancelButtonAccessibilityLabel}
							>
								<View style={[styles.cancelStyle, importedProps.cancelStyle]}>
									<CustomText
										style={[styles.cancelTextStyle,importedProps.cancelTextStyle]}
										{...importedProps.cancelTextPassThruProps}
									>
										{importedProps.cancelText}
									</CustomText>
								</View>
							</CustomPressable>
						</View>
					</View>
				</CustomPressable>
			</Modal>
		)
	}

	return (
		<View style={[styles.touchableStyle, importedProps.style]} {...importedProps.passThruProps}>
			{dataPicker()}
			{importedProps.customSelector ? (
				importedProps.customSelector
				) : (
				<CustomPressable
					hitSlop={importedProps.modalOpenerHitSlop}
					activeOpacity={importedProps.touchableActiveOpacity}
					style={importedProps.touchableStyle}
					onPress={open}
					disabled={importedProps.disabled}
					accessible={importedProps.openButtonContainerAccessible}
				>
					<View style={importedProps.childrenContainerStyle} >
						<View style={[styles.selectStyle, importedProps.selectStyle]}>
							<CustomText style={importedProps.initValue === selected ? [styles.initValueTextStyle, importedProps.initValueTextStyle] : [styles.selectTextStyle, importedProps.selectTextStyle]} {...importedProps.selectTextPassThruProps}>
								{selected}
							</CustomText>
							{importedProps.rightElement && (
								importedProps.rightElement.onPress ? (
									<CustomPressable onPress={importedProps.rightElement.onPress}>
										{importedProps.rightElement()}
									</CustomPressable>
								) : (
									<View>
										{importedProps.rightElement()}
									</View>
								)
							)}
						</View>
					</View>
				</CustomPressable>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	overlayStyle: {
		flex:               1,
		padding:           Layout.small ? '10%' : '25%',
		paddingVertical:   '15%',
		justifyContent:    'center',
		backgroundColor:   Colors.blackTransparent82,
	},

	optionContainer: {
		borderRadius:    BORDER_RADIUS,
		flexShrink:       1,
		marginBottom:    8,
		padding:         PADDING,
		backgroundColor: Colors.whiteTransparent85,
	},

	cancelContainer: {
		alignSelf: 'stretch',
	},

	selectStyle: {
		padding:      PADDING,
		borderRadius: BORDER_RADIUS,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},

	selectTextStyle: {
		textAlign: 'left',
		color:     Colors.text,
		fontSize:  FONT_SIZE,
	},

	cancelStyle: {
		borderRadius:    BORDER_RADIUS,
		backgroundColor: Colors.whiteTransparent85,
		padding:         PADDING,
	},

	cancelTextStyle: {
		textAlign: 'center',
		color:     Colors.text,
		fontSize:  FONT_SIZE,
	},

	optionStyle: {
		padding:           PADDING,
		borderBottomWidth: 1,
		borderBottomColor: '#ccc',
	},

	optionTextStyle: {
		textAlign: 'center',
		fontSize:  FONT_SIZE,
		color:     Colors.blueAzure,
	},

	sectionStyle: {
		padding:           PADDING * 2,
		borderBottomWidth: 1,
		borderBottomColor: '#ccc',
	},

	sectionTextStyle: {
		textAlign: 'left',
		fontSize:  FONT_SIZE,
	},

	touchableStyle: {
		backgroundColor:  Colors.white,
		borderRadius:     BORDER_RADIUS,
		...ShadowStyles.shadowInputs,
	},

	initValueTextStyle: {
		textAlign: 'left',
		fontSize:  FONT_SIZE,
		color:     Colors.text,
	},
})