// libraries
import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

// api
import { getConversationId } from '../../../services/api/Inbox'

// components
import { CustomText } from '../../text/StyledText'
import OptionButton from '../../buttons/OptionButton'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import OptionsDialog from '../../dialogs/OptionsDialog'
import { txt } from '../../../locales/i18n'
import Fontsizes from '../../../constants/Fontsizes'
import { getHexColor } from '../../../utils/getHexColor'
import Card from '../../Card'

export default function GroupMemberItem({ item, ownUserRank, ...props }) {
  const [showOptionsDialog, setShowOptionsDialog] = useState(false)
  
  const user = useSelector((state) => state.user)

  const renderText = () => {
    const now = new Date()
    let text = item[item.rank_id && item.rank_id !== 6 ? 'username' : 'email']
    //Second check is for K7 group to show age of invites to group 287
    text = item.rank_id === 1 ? `${text} (Admin)` : item.rank_id === 4 && item.birth_year && props.group.id === 287 ? `${text + ' (' + (now.getFullYear() - item.birth_year) + ')'}` : text
    return text
  }

  const leaveGroupOption = {
    label: txt('groups.members.leave'),
    icon: "user-check",
    action: async () => {
      await props.leaveGroup(props.group.id)
      setShowOptionsDialog(false)
    },
  }

  const sendMessageOption = {
    label: txt('groups.members.sendMessage'),
    icon: "comment-lines",
    action: async () => {
      if (!item.user_id) return
      const conversationId = await getConversationId({
        groupId: props.group.id,
        receiverId: item.user_id,
      })
      if (conversationId) await props.navigateToConversation(conversationId)
      else await props.navigateToNewConversation(item.user_id)
      setShowOptionsDialog(false)
    },
  }

  const makeAdminOption = {
    label: txt('groups.members.makeAdmin'),
    icon: "user-check",
    action: async () => {
      await props.makeAdmin(item)
      setShowOptionsDialog(false)
    },
  }

  const removeMemberOption = {
    label: txt('groups.members.removeMember'),
    icon: "times-circle",
    action: async () => {
      await props.removeMember(item)
      setShowOptionsDialog(false)
    },
    type: 'danger',
  }

  const approveRequestOption = {
    label: txt('groups.members.approve'),
    icon: "check",
    action: async () => {
      await props.approveRequest(item)
      setShowOptionsDialog(false)
    },
  }

  const declineRequestOption = {
    label: txt('groups.members.decline'),
    icon: "times",
    action: async () => {
      await props.declineRequest(item)
      setShowOptionsDialog(false)
    },
    type: 'danger',
  }

  const unbanUserOption = {
    label: txt('groups.members.unbanUser'),
    icon: "lock-open-alt",
    action: async () => {
      await props.unbanUser(item)
      setShowOptionsDialog(false)
    },
  }

  const deleteInviteOption = {
    label: txt('groups.members.deleteInvitation'),
    icon: "trash",
    type: 'danger',
    action: async () => {
      await props.deleteInvitation(item)
      setShowOptionsDialog(false)
    },
  }

  const options = []

  if (item.rank_id > 0 && item.rank_id < 4 && user.id != item.user_id) {
    options.push(sendMessageOption)
  }

  if (ownUserRank === 1 && (item.rank_id === 1 || item.rank_id === 2 || item.rank_id === 3) && (user.id !== item.user_id)) {
    options.push(removeMemberOption)
  }

  if (ownUserRank === 1 && (item.rank_id === 2 || item.rank_id === 3)) {
    options.push(makeAdminOption)
  }

  if (ownUserRank === 1 && item.rank_id === 4) {
    options.push(approveRequestOption, declineRequestOption, sendMessageOption)
  }

  if (ownUserRank === 1 && item.rank_id === 8) {
    options.push(unbanUserOption)
  }

  if (ownUserRank === 1 && !item.rank_id) {
    options.push(deleteInviteOption)
  }

  if (user.id === item.user_id) {
    options.push(leaveGroupOption)
  }

  return (
    <Card
      onPress={() => setShowOptionsDialog(!showOptionsDialog)}
      style={[styles.container, { backgroundColor: props.group.theme?.secondary || Colors.white }]}
    >
      <View style={styles.textContainer}>
        <View
          style={[
            styles.avatar,
            { backgroundColor: item.user_id ? getHexColor(item.user_id) : Colors.darkDarkGrey },
          ]}
        >
          <NormalIcon
            accessibilityLabel={txt('fullPost.usernameIcon')}
            name="user"
            size={Fontsizes.s}
            color={Colors.white}
            stroke="fas"
          />
        </View>
        <CustomText font="smallBold" numberOfLines={1}>
          {renderText()}
        </CustomText>
      </View>

      <View>
        <OptionButton
          size={24}
          hoverColor={'skyBlue'}
          onPress={() => setShowOptionsDialog(true)}
        />
      </View>
      <OptionsDialog
        visible={showOptionsDialog}
        onDismiss={() => setShowOptionsDialog(false)}
        options={options}
        title={renderText()}
      />
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 10,
    padding: 5,
  },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 7,
    padding: 5,
  },
  avatar: {
    borderRadius: 100,
    marginRight: 8,
    opacity: 0.4,
    padding: 5,
  },
})