// libraries
import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import moment from 'moment'

// api
import {
	createBooking,
	getPreviousBookers,
	getWaitlistUser,
	removeFromWaitlist
} from '../../../../services/api/snakSammen'

// components
import { SnakSammenDuration } from '../volunteer/SnakSammenDurationSelector'
import { ButtonPrimary } from '../../../buttons/StyledButtons'
import { CustomText } from '../../../text/StyledText'
import DatePicker from '../../../dates/DatePicker'

// other
import { ShadowStyles } from '../../../../styles'
import RegexPatterns from '../../../../constants/RegexPatterns'
import { txt } from '../../../../locales/i18n'
import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import Colors from "../../../../constants/Colors"
import NormalIcon from "../../../icons/NormalIcon"
import CustomPressable from "../../../pressables/CustomPressable"
import { useSelector } from "react-redux"
import { SnakSammenInput } from "../volunteer/VolunteerProfile"
import RenderPreviousBookers from "../volunteer/RenderPreviousBookers"
import Layout from "../../../../constants/Layout"

export default function CreateSessionDialog({ visible, onDismiss, reloadAll, user, waitlist = false }) {
	moment.locale([txt('global.locale')])
	const userRedux = useSelector((state) => state.user)

	const [startDate, setStartDate] = useState(new Date())
	const [durationChosen, setDurationChosen] = useState(15)
	const [durationLabelChosen, setDurationLabelChosen] = useState(txt('videoChat.volunteer.create.duration15'))
	const [emailToInvite, setEmailToInvite] = useState('')
	const [isEmailInviteChecked, setIsEmailInviteChecked] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [loading, setLoading] = useState(false)

	const [previousBookers, setPreviousBookers] = useState([])
	const [selectedBooker, setSelectedBooker] = useState('')
	const [resetTrigger, setResetTrigger] = useState(null)

	const [created, setCreated] = useState(false)
	const minTime = useRef(new Date().setHours(5, 0))
	const maxTime = useRef(new Date().setHours(23, 0))
	const maximumDate = useRef(new Date().setMonth(new Date().getMonth() + 6))

	useEffect(() => {
		if (waitlist === false) getPreviousBookers().then(setPreviousBookers)
		roundUpDate()
	}, [])

	useEffect(() => {
		setErrorMsg('')
		if (isEmailInviteChecked) validateEmail()
	}, [emailToInvite])

	useEffect(() => {
		if (user && waitlist) getWaitlistUser(user.userId).then((u) => {
			if (u?.email) {
				setEmailToInvite(u.email)
				setIsEmailInviteChecked(true)
			}
		})
		if (user && previousBookers?.length > 0 && waitlist === false) {
			const booker = previousBookers.find((b) => b.userId === user.userId)
			selectBooker(booker)
		}
	}, [user, waitlist, previousBookers])

	function handleErrorMessages(message) {
		if (message === 'assigned not found') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.notFound'))
		if (message === 'assigned and volunteer are the same') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.identical'))
		if (message === 'volunteer already booked in time frame') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.volunteerAlreadyBooked'))
		if (message === 'volunteer or assigned already booked in time frame') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.participantAlreadyBooked'))
		return setErrorMsg(txt('videoChat.volunteer.bookingErrors.invalidInputs'))
	}

	function roundUpDate() {
		const coeff = 1000 * 60 * 5
		const roundedStartDate = new Date(Math.ceil(startDate / coeff) * coeff)
		setStartDate(roundedStartDate)
	}

	function checkIfDateAndTimeIsValid() {
		const date = new Date(startDate)
		const isDateValid = date > new Date()
		if (isDateValid) return true
		setErrorMsg(txt('invalidNotice.dateTimeHasPassed'))
		return false
	}

	function validateEmail() {
		if (!isEmailInviteChecked) {
			setEmailToInvite('')
			return true
		}
		if (emailToInvite === userRedux.email) {
			setErrorMsg(txt('videoChat.volunteer.bookingErrors.cannotInviteSelf'))
			return false
		}
		const conditions = RegexPatterns.email.map((rule) => new RegExp(rule, 'g'))
		const isEmailValid = conditions.map((condition) => condition.test(emailToInvite))
		if (isEmailValid.includes(false) && emailToInvite?.length > 0) {
			setErrorMsg(txt('videoChat.volunteer.bookingErrors.invalidEmail'))
			return false
		} else return true
	}

	async function create() {
		setLoading(true)

		const date = new Date(startDate)
		const newEndDate = new Date(startDate)
		newEndDate.setMinutes(date.getMinutes() + Number(durationChosen))

		const created = await createBooking({
			startDate: startDate,
			endDate: newEndDate,
			emailToInvite: emailToInvite,
		})

		if (!created.id) {
			handleErrorMessages(created)
			return setLoading(false)
		}

		setCreated(true)
		resetInputs()
		setIsEmailInviteChecked(false)
		setSelectedBooker('')
		setTimeout(() => setCreated(false), 600)

		if (waitlist) {
			await removeFromWaitlist(user.userId)
		}
		reloadAll?.()
		setLoading(false)
	}

	function resetInputs() {
		setErrorMsg('')
		setEmailToInvite('')
		setIsEmailInviteChecked(false)
		setSelectedBooker('')
		setStartDate(new Date())
		setDurationChosen(15)
		setDurationLabelChosen(txt('videoChat.volunteer.create.duration15'))
		resetBooker()
		onDismiss()
	}

	async function handleBookingSubmit() {
		const isValid = (checkIfDateAndTimeIsValid() && validateEmail())
		if (isValid) await create()
	}

	function setMinTime() {
		const today = new Date()
		const isToday = (
			startDate.getDate() === today.getDate() &&
			startDate.getMonth() === today.getMonth() &&
			startDate.getFullYear() === today.getFullYear()
		)
		if (isToday) return new Date()
		return minTime.current
	}

	function handleEmailChange(email) {
		setEmailToInvite(email)
		if (email.length > 0) return setIsEmailInviteChecked(true)
		setIsEmailInviteChecked(false)
	}

	function handleDateChange(date, isTime) {
		if (!isTime) return setStartDate(date)

		setStartDate((startDate) => {
			const newDate = new Date(startDate)
			newDate.setHours(date.getHours(), date.getMinutes(), 0, 0)
			return newDate < new Date() ? new Date() : newDate
		})
	}

	function handleDurationChange(duration) {
		setDurationLabelChosen(duration.label)
		setDurationChosen(duration.value)
	}

	const selectBooker = (booker) => {
		setSelectedBooker(booker.username)
		setIsEmailInviteChecked(true)
		setEmailToInvite(booker.email)
	}

	const resetBooker = () => {
		setSelectedBooker('')
		setEmailToInvite('')
		setIsEmailInviteChecked(false)
		setResetTrigger(Date.now())
	}

	function handleClose() {
		resetInputs()
		onDismiss()
	}

	return (
		<CustomDialog visible={visible} onDismiss={handleClose} style={styles.dialog}>

			<CustomPressable onPress={handleClose} style={styles.closeIcon}>
				<NormalIcon name="times" size={20} color={Colors.darkGrey}/>
			</CustomPressable>

			<ContentContainer>

				<CustomText font="gotham-bold-xlarge" style={{ alignSelf: 'center' }}>
					{txt('videoChat.volunteer.create.createSession')}
				</CustomText>

				{waitlist && (
					<CustomText font="gotham-small" style={{ marginVertical: 12 }}>
						{txt('videoChat.volunteer.create.remember')}
					</CustomText>
				)}

				<CustomText font="gotham-bold-small" style={{marginBottom: 12}}>
					{txt('videoChat.volunteer.create.date')}
				</CustomText>

				<DatePicker
					date={startDate}
					minTime={setMinTime()}
					maxTime={maxTime.current}
					minDate={new Date()}
					maxDate={maximumDate.current}
					minuteInterval={5}
					handleDateChange={handleDateChange}
					textStyle={{ fontFamily: 'gotham-book', fontSize: 15 }}
					style={styles.pickerStyle}
					buttonStyle={[styles.createButton, { marginTop: 12, marginLeft: 0 }]}
					buttonTitleStyle={styles.createButtonTitle}
				>
					<NormalIcon
						name="chevron-down"
						size={14}
						color={Colors.inactive}
						style={{ paddingRight: 10 }}
					/>
				</DatePicker>

				<View style={{marginTop: 12}}>
					<SnakSammenDuration
						duration={durationLabelChosen}
						handleDurationChange={handleDurationChange}
						style={styles.pickerStyle}
					/>
				</View>

				<CustomText font="gotham-bold-small" style={{ marginTop: 24, marginBottom: 12}}>
					{txt('videoChat.volunteer.create.invite')}
				</CustomText>

				{waitlist === false && (
					<View style={{marginBottom: 12}}>
						{previousBookers.length > 0 && (
							<RenderPreviousBookers
								data={previousBookers}
								onChange={selectBooker}
								reset={resetBooker}
								selected={selectedBooker}
								resetTrigger={resetTrigger}
							/>
						)}
					</View>
				)}

				{!selectedBooker && (
					<SnakSammenInput
						value={emailToInvite}
						placeholder={txt('videoChat.volunteer.create.inviteEmail')}
						onChange={handleEmailChange}
						style={{ fontFamily: 'gotham-book', color: Colors.text }}
						inputMode="email"
						autoCapitalize="none"
						error={errorMsg !== null && errorMsg !== ''}
						errorMessage={errorMsg}
						editable={waitlist === false}
					/>
				)}

				<ButtonPrimary
					title={txt('videoChat.volunteer.create.createSession')}
					style={[styles.button, errorMsg && {marginTop: 12}]}
					titleStyle={styles.buttonTitle}
					success={created}
					onPress={handleBookingSubmit}
					loading={loading}
				/>

			</ContentContainer>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	dialog: {
		maxWidth: 500,
		width: '95%'
	},
	pickerStyle: {
		backgroundColor: Colors.white,
		borderWidth: 1,
		borderColor: Colors.redCrossGrey,
		borderRadius: 6,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		...ShadowStyles.unsetShadows
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115
	},
	buttonTitle: {
		color: Colors.redCross.textSecondary,
		fontFamily: 'gotham-bold',
		fontSize: 15,
	},

	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
	createButton: {
		borderRadius: 6,
		backgroundColor: Colors.redCross.red,
		paddingVertical: 10,
		marginLeft: Layout.isSmallDevice ? 0 : 12,
		marginTop: Layout.isSmallDevice ? 24 : 48,
		minWidth: 115,
	},
	createButtonTitle: {
		color: Colors.white,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
})
