import React, { useEffect, useRef, useState } from 'react'
import { View, ScrollView, Platform, StyleSheet } from 'react-native'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { RoundBtn, DeleteBtn } from '../../buttons/StyledButtons'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { ShadowStyles, SpacingStyles, TypographyStyles } from '../../../styles'
import { UploadImage } from '../../../services/api/UploadImage'
import { Picker } from '@react-native-picker/picker'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import * as AddressService from '../../../services/api/Addresses'
import { useRoute } from '@react-navigation/native'
import ChooseFacultyItem from './ChooseFacultyItem'
import store from '../../../redux/store'
import { getFacilties } from '../../../services/api/Unions'
import imageBobl from '../../../assets/images/bobl.png'
import { useSelector } from 'react-redux'
import CustomPressable from "../../pressables/CustomPressable"
import ModalSelectorCustom from "../../modalSelectors/ModalSelectorCustom"

export default function EditUnion(props) {
  const [loading, setLoading] = useState(true)
  const [union, setUnion] = useState({
    title: '',
    body: '',
    url: '',
    municipality_id: '',
    municipality_name: '',
    img_id: null,
    address: '',
    city: '',
    postal_code: '',
    latlng: null,
    faculties: [],
  })
  const [visible, setVisible] = useState(false)
  const [isAddressValid, setIsAddressValid] = useState(true)
  const [isPostalValid, setIsPostalValid] = useState(true)
  const [isMissingPostalCode, setIsMissingPostalCode] = useState(false)
  const [titleTooShort, setTitleTooShort] = useState(false)
  const [descriptionTooShort, setDescriptionTooShort] = useState(false)
  const [image, setImage] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const SUGGESTIONS_PER_PAGE = 4
  const timeout = useRef()

  const [faculties, setFaculties] = useState([])
  const [facultiesVisible, setFacultiesVisible] = useState(false)
  const [selectedFaculties, setSelectedFaculties] = useState(new Set())
  const [isMissingFaculties, setIsMissingFaculties] = useState(false)
  const municipalities = useSelector(({ municipalities }) => municipalities)

  const route = useRoute()
  const universeName = route.params?.universe?.toLowerCase()
  const universe = store.getState().user.universes.find((u) => u.name === universeName)

  useEffect(() => {
    getFacilties().then(setFaculties)
  }, [route.params])

  useEffect(() => {
    if (loading) {
      props.getFullUnion(props.id).then((u) => {
		const uri = u.img_filename ? Paths.imageUrlBase + u.img_filename : null
        setUnion({ ...u , image: { uri }})
        setSelectedFaculties(new Set(u.faculties.map((f) => f.id)))
      })
      setLoading(false)
    }
  }, [])

  function updateUnion(update) {
    setUnion((prev) => ({ ...prev, ...update }))
  }

  const uploadImg = () => {
    if (image) {
      return UploadImage(image).catch((err) => {
        if (__DEV__) console.log('IMAGE UPLOAD FAIL ', err)
      })
    }
  }

  const saveUnion = async () => {
    const isAddressValid = await validateAddress()
    if (validateUnion() && isAddressValid) {
      const img_id = await uploadImg()
      const data = {
        id: union.id,
        title: union.title,
        body: union.body,
        url: union.url,
        municipality_id: parseInt(union.municipality_id, 10),
        img_id: img_id || union.img_id,
        address: union.address === '' ? null : union.address,
        city: union.city,
        postal_code: union.postal_code === '' ? null : union.postal_code,
        lat: union.latlng === undefined ? null : union.latlng.lat,
        lng: union.latlng === undefined ? null : union.latlng.lng,
      }

      if (universe?.id === 12) {
        data.faculties = Array.from(selectedFaculties)
      }
      const unionUpdated = await props.updateUnion(data)
      if (unionUpdated) {
        updateUnion(unionUpdated)
        props.navigation.goBack()
      }
    }
  }

  const validateAddress = async () => {
    const { address, postal_code } = union
    if (address?.length > 0) {
      if (!postal_code) {
        setIsMissingPostalCode(true)
        return false
      } else setIsMissingPostalCode(false)

      const data = await AddressService.getAddress(address, postal_code)
      if (data) {
        setIsAddressValid(true)
        return true
      }
      return false
    } else {
      // empty address is valid
      updateUnion({ latlng: { lat: null, lng: null } })
      setIsAddressValid(true)
      setIsMissingPostalCode(false)
      return true
    }
  }

  function validateUnion() {
    // TODO: write validations
    const { title, body } = union
    if (title.length > 0) setTitleTooShort(false)
    if (body.length > 0) setDescriptionTooShort(false)
    if (!isPostalValid) return false
    if (selectedFaculties.size < 1 && universe?.id === 12) {
      setIsMissingFaculties(true)
      return false
    }
    return true
  }

  const removeUnion = async () => {
    const success = await props.deleteUnion(union.id)
    if (success) {
      setVisible(false)
      props.navigation.pop(2)
    }
  }

  const keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  function pickMunicipality() {
    return (
      <ModalSelectorCustom
        data={municipalities}
        keyExtractor={(data) => data.id?.toString()}
        labelExtractor={(data) => data.name}
        key={(data) => data.id}
        initValue={union.municipality_name}
        onChange={(value) => updateUnion({ municipality_id: value.id })}
      />
    )
  }

  const handleAddressChange = async (value) => {
    clearTimeout(timeout.current)
    updateUnion({ address: value })

    timeout.current = setTimeout(async () => {
      const suggestions = await AddressService.getSuggestions(value)
      if (suggestions.length === 0) setIsAddressValid(false)
      else setIsAddressValid(true)
      setSuggestions(suggestions)
    }, 800)
  }

  async function handleSuggestionClick(suggestion) {
    const address = suggestion.tekst.split(',')[0]
    const otherInfo = suggestion.adgangsadresse
    const data = await AddressService.getAddress(address, otherInfo.postnr)
    const latlng = data.adgangsadresse.adgangspunkt.koordinater
    updateUnion({
      address: address,
      postal_code: otherInfo.postnr,
      city: otherInfo.postnrnavn,
      latlng: { lat: latlng[1], lng: latlng[0] },
    })
    setSuggestions([])
  }

  const renderSuggestions = () => (
    <View style={styles.suggestionsContainer}>
      {suggestions.map((suggestion, index) => {
        const borderWidth = index !== suggestions.length - 1 ? 1 : 0
        return (
          <CustomPressable
            style={[styles.suggestion, { borderBottomWidth: borderWidth }]}
            key={index.toString()}
            onPress={() => handleSuggestionClick(suggestion)}
          >
            <CustomText>{suggestion.tekst}</CustomText>
          </CustomPressable>
        )
      })}
    </View>
  )

  const handlePostalCodeChange = async (postalCode) => {
    clearTimeout(timeout.current)
    postalCode = postalCode.replace(/[^0-9]/g, '')
    if (postalCode.length === 0) setIsPostalValid(true)
    if (postalCode.length > 0) {
      timeout.current = setTimeout(async () => {
        const city = await AddressService.getCityFromPostalCode(postalCode)
        updateUnion({ city: city ?? '' })
        setIsPostalValid(city !== null)
      }, 400)
    }
    updateUnion({ postal_code: postalCode })
  }

  const handleFaculties = (faculties) => {
    setSelectedFaculties(faculties)
    setFacultiesVisible(false)
  }

  if (!loading && union) {
    return (
      <View style={styles.container}>
        <ScrollView>
          <View style={styles.margin10}>
            <View style={styles.imagePickerContainer}>
              <ImagePicker
                editMode
                setImage={setImage}
                defaultImage={union?.image?.uri ?? null}
                onDelete={() => updateUnion({ img_id: null, img_filename: null })}
                placeholder={imageBobl}
              />
            </View>

            <View style={styles.marginInput}>
              <CustomText font="title" text={txt('unions.create.title')} style={styles.marginBottom} />
              <CustomInputs value={union.title} onChangeText={(text) => updateUnion({ title: text })} />
            </View>
            <View style={styles.marginInput}>
              <CustomText font="title" text={txt('unions.create.description')} style={styles.marginBottom} />
              <CustomMultilineInputs value={union.body} onChangeText={(value) => updateUnion({ body: value })} />
            </View>
            <View style={styles.marginInput}>
              <CustomText font="title" text={txt('unions.create.address')} style={styles.marginBottom} />
              <CustomInputs
                accessibilityLabel={txt('unions.create.address')}
                accessibilityHint={txt('unions.create.addressHint')}
                value={union.address}
                onChangeText={handleAddressChange}
              />
              {!isAddressValid && (
                <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                  {txt('unions.create.invalidAddress')}
                </CustomText>
              )}
              {suggestions.length > 0 && union.address !== '' && renderSuggestions()}
            </View>

            <View style={styles.inputRow}>
              <View style={styles.marginInputSplitPostalCode}>
                <CustomText
                  font="title"
                  text={txt('unions.create.postalCode')}
                  style={styles.marginBottom}
                  accessible
                />
                <CustomInputs
                  accessibilityLabel={txt('unions.create.postalCode')}
                  accessibilityHint={txt('unions.create.postalCodeHint')}
                  value={union.postal_code === null ? '' : String(union.postal_code)}
                  inputMode="numeric"
                  maxLength={4}
                  onChangeText={handlePostalCodeChange}
                />
                {!isPostalValid && (
                  <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                    {txt('unions.create.invalidPostalCode')}
                  </CustomText>
                )}
                {isMissingPostalCode && (
                  <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                    {txt('unions.create.postalCodeRequired')}
                  </CustomText>
                )}
              </View>
              <View style={[styles.marginInputSplitCity, { zIndex: -1 }]}>
                <CustomText font="title" text={txt('unions.create.city')} style={styles.marginBottom} />
                <CustomInputs
                  accessibilityLabel={txt('unions.create.city')}
                  accessibilityHint={txt('unions.create.city')}
                  value={union.city}
                  onChangeText={(value) => updateUnion({ city: value })}
                />
              </View>
            </View>

            <View style={(styles.marginInput, { zIndex: -1 })}>
              <CustomText font="title" text={txt('unions.create.url')} style={styles.marginBottom} />
              <CustomInputs value={union.url} onChangeText={(text) => updateUnion({ url: text })} />
            </View>

            {!universe && (
              <View style={(styles.marginInput, { zIndex: -1 })}>
                <View style={[styles.marginBottom, { marginTop: 12 }]}>
                  <CustomText font="title" text={txt('unions.create.municipality')} />
                </View>
                <View style={styles.marginBottom}>
                  <CustomText font="small" text={txt('unions.create.municipalityText')} />
                </View>
                {pickMunicipality()}
              </View>
            )}

            {union.universe_id === 12 && universe?.id === 12 && faculties.length > 0 && (
              <View>
                <CustomText font="title" text={txt('unions.create.city')} style={{ marginTop: 12, marginBottom: 6 }} />
                <CustomPressable
                  onPress={() => setFacultiesVisible(true)}
                  style={{
                    backgroundColor: Colors.white,
                    borderRadius: 10,
                    ...ShadowStyles.shadowInputs,
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <CustomText
                    font="title"
                    text={
                      selectedFaculties.size === 1
                        ? selectedFaculties.size + txt('unions.create.faculty.selected')
                        : selectedFaculties.size > 1
                        ? selectedFaculties.size + txt('unions.create.faculty.multipleSelected')
                        : txt('unions.create.faculty.select')
                    }
                  />
                </CustomPressable>
                <ChooseFacultyItem
                  visible={facultiesVisible}
                  close={() => setFacultiesVisible(false)}
                  faculties={faculties}
                  onPress={handleFaculties}
                  defaultFaculties={selectedFaculties}
                  required
                />
                {isMissingFaculties && (
                  <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                    {txt('unions.create.faculty.invalid')}
                  </CustomText>
                )}
              </View>
            )}

            <View style={styles.buttonsContainer}>
              <DeleteBtn
                style={styles.deleteButton}
                title={txt('unions.edit.deleteButton')}
                onPress={() => setVisible(true)}
              />
              <CustomDialog visible={visible}>
                <ContentContainer>
                  <CustomText>{txt('unions.edit.deleteText')}</CustomText>
                </ContentContainer>
                <ActionButton onPress={removeUnion}>{txt('post.deletePostYes')}</ActionButton>
                <ActionButton onPress={() => setVisible(false)}>{txt('post.deletePostNo')}</ActionButton>
              </CustomDialog>
              <RoundBtn title={txt('unions.edit.saveButton')} onPress={saveUnion} />
            </View>
          </View>
        </ScrollView>
        {keyboardSpacerShow()}
      </View>
    )
  }
  return <View />
}

const styles = StyleSheet.create({
  buttonsContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 10,
  },
  container: { flex: 1, ...SpacingStyles.widthAndHeight },
  errorMessage: {
    color: Colors.errorBackground,
    ...TypographyStyles.textSmallBold,
    marginBottom: 5,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    zIndex: -1,
  },
  margin10: { margin: 10 },
  marginBottom: { marginBottom: 5 },
  marginInput: { marginBottom: 15 },
  marginInputSplitCity: {
    flex: 1,
    marginBottom: 15,
  },
  marginInputSplitPostalCode: {
    flex: 1,
    marginBottom: 15,
    marginRight: 5,
  },
  picker: {
    backgroundColor: Colors.white,
    padding: Platform.OS === 'android' ? 0 : 10,
    paddingLeft: Platform.OS === 'android' ? 6 : 10,
    borderRadius: 12,
    marginTop: 6,
    borderRight: 24,
    ...ShadowStyles.shadowInputs,
  },
  suggestion: {
    borderBottomColor: Colors.blackTransparent,
    padding: 10,
  },
  suggestionsContainer: {
    ...ShadowStyles.shadowInputs,
    backgroundColor: Colors.white,
    borderRadius: 12,
    marginTop: 20,
    position: 'absolute',
    top: 48,
    width: '100%',
    zIndex: 1,
  },
  textCenter: { textAlign: 'center' },
})
