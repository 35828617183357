import React, { useEffect, useRef, useState } from 'react'
import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Image, View } from 'react-native'

import * as ExpoImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import * as MediaLibrary from "expo-media-library"

import ImageCropper from './ImageCropper'
import { CameraModal } from '../camera-modal/camera-modal'
import { CustomText } from '../text/StyledText'
import NormalIcon from '../icons/NormalIcon'

import OptionsDialog from '../dialogs/OptionsDialog'
import CustomDialog from '../dialogs/CustomDialog'
import { ScrollableDialog, ActionButton, ContentContainer } from '../dialogs/CustomDialog'

import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import imageBobl from '../../assets/images/bobl.png'
import CustomPressable from "../pressables/CustomPressable"
import { openSystemSettings } from "../../utils/getPermissions"

const small = Dimensions.get('window').width < 500

export default function ImagePicker({ editMode = false, hideDelete = false, placeholder = imageBobl, placeholderText = true, ...props }) {
  const [cropping, setCropping] = useState(false)
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [libraryPermission, setLibraryPermission] = useState(null)
  const [imageUri, setImageUri] = useState(null)

  const cameraRef = useRef(null)

  useEffect(() => {
    if (imageUri !== null) return
    setImageUri(props?.defaultImage ?? null)
  }, [props.defaultImage])

  function handleCameraConfirm(image) {
    setImageUri(image.uri)
    props.setImage(image)
  }

  function openCamera() {
    const camera = cameraRef.current
    if (camera === null) return

    setOptionsVisible(false)
    setTimeout(() => {
      camera.open()
    }, 200)
  }

  async function openLibrary() {
    if (Platform.OS !== 'web') {
      let permission = await MediaLibrary.getPermissionsAsync()
      if (!permission.granted) {
        if (!permission.canAskAgain) {
          setOptionsVisible(false)
          await openSystemSettings()
        } else {
          permission = await MediaLibrary.requestPermissionsAsync()
        }
      }
      const libraryPermission = permission.granted
      setLibraryPermission(libraryPermission)
      if (libraryPermission === false) return
    }

    const mediaTypes = ExpoImagePicker.MediaTypeOptions.Images
    const options = { mediaTypes, allowsEditing: true, aspect: [1, 1], base64: true }
    const result = await ExpoImagePicker.launchImageLibraryAsync(options)

    setOptionsVisible(false)

    if (result.canceled || result.assets.length === 0) return
    const [image] = result.assets
    setImageUri(image.uri)

    if (Platform.OS !== 'web') {
      props.setImage(image)
    }

    if (Platform.OS === 'web') {
      setCropping(true)
    }
  }

  function deleteImage() {
    setImageUri(null)
    props.setImage(null)

    setOptionsVisible(false)
    props.onDelete?.()
  }

  async function rotateImage() {
    if (imageUri === null) return
    const actions = [{ rotate: 90 }]
    const options = { format: ImageManipulator.SaveFormat.JPEG }
    const result = await ImageManipulator.manipulateAsync(imageUri, actions, options)

    setImageUri(result.uri)
    props.setImage(result)
  }

  function handleCropping(uri) {
    setCropping(false)
    setImageUri(uri)
    props.setImage({ uri })
  }

  function getOptions() {
    const options = [
      {
        label: txt('imagePicker.select'),
        icon: "images",
        action: openLibrary,
      },
      {
        label: txt('imagePicker.take'),
        icon: "camera",
        action: openCamera,
      },
    ]

    if (hideDelete || imageUri === null) {
      return options
    }

    options.push({
      label: txt('imagePicker.remove'),
      icon: "times",
      action: deleteImage,
    })

    return options
  }

  return (
    <>
      <View style={styles.container}>
        {editMode ? (
          <CustomPressable onPress={() => setOptionsVisible(true)}>
            <Image
              accessibilityIgnoresInvertColors
              style={[small ? styles.imageSmall : styles.imageMedium, imageUri ? {} : styles.border, placeholderText && !imageUri && {opacity: 0.5}]}
              source={imageUri ? { uri: imageUri } : placeholder}
            />
          {placeholderText && !imageUri && (
            <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
              <CustomText font="bold" style={{fontSize: 14}}>{txt('imagePicker.select')}</CustomText>
            </View>
          )}
          </CustomPressable>
        ) : (
          <Image
            accessibilityIgnoresInvertColors
            style={[small ? styles.imageSmall : styles.imageMedium, imageUri ? {} : styles.border]}
            source={imageUri ? { uri: imageUri } : placeholder}
          />
        )}

        {editMode && (
          <>
            <CustomPressable style={[styles.button, styles.editButton]} onPress={() => setOptionsVisible(true)}>
              <NormalIcon name="pen" stroke={'far'} size={16} color={Colors.white} />
            </CustomPressable>

            {Platform.OS !== 'web' && imageUri !== null && (
              <CustomPressable style={[styles.button, styles.rotateButton]} onPress={rotateImage}>
                <NormalIcon name="sync" stroke={'fal'} size={16} color={Colors.white} />
              </CustomPressable>
            )}
          </>
        )}
      </View>

      <CustomDialog style={{ padding: 12 }} visible={cropping}>
        <ImageCropper image={imageUri} saveCroppedImage={handleCropping} />
      </CustomDialog>

      <OptionsDialog visible={optionsVisible} onDismiss={() => setOptionsVisible(false)} options={getOptions()} />
      <CameraModal ref={cameraRef} aspectRatio={1 / 1} onConfirm={handleCameraConfirm} />

      <ScrollableDialog visible={libraryPermission === false}>
        <ContentContainer>
          <CustomText style={{ color: Colors.red }} font="bold">
            {txt('imagePicker.libraryAccessHeader')}
          </CustomText>

          <CustomText style={styles.marginTop}>{txt('imagePicker.libraryAccessInfo')}</CustomText>
        </ContentContainer>

        <ActionButton onPress={() => setLibraryPermission(null)} style={styles.dialogButton}>
          {txt('global.ok')}
        </ActionButton>
      </ScrollableDialog>
    </>
  )
}

const styles = StyleSheet.create({
  border: {
    borderWidth: 0.2,
    borderColor: Colors.lightBlue,
  },

  container: {
    flex: 1,
  },

  imageMedium: {
    borderRadius: 200,
    height: 175,
    width: 175,
  },

  imageSmall: {
    borderRadius: 200,
    height: 150,
    width: 150,
  },

  button: {
    padding: 10,
    margin: 12,
    backgroundColor: Colors.blueAzure,
    borderRadius: 32,
    position: 'absolute',
  },

  editButton: {
    left: small ? 95 : 120,
    top: small ? 95 : 120,
  },

  rotateButton: {
    left: small ? -5 : 0,
    top: small ? 95 : 120,
  },
})
