import React from 'react'
import { connect } from 'react-redux'
import { Platform, StyleSheet } from 'react-native'
import { CustomSnackbar } from './Snackbar'
import layout from '../../constants/Layout'
import { setAnnouncement } from '../../redux/actions'

class AppAnnouncement extends React.Component {
  render() {
    return (
      <CustomSnackbar
        visible={this.props.announcement}
        onDismiss={() => this.props.setAnnouncement('')}
        text={this.props.announcement}
        duration={6000}
        style={styles.container}
      />
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginBottom: Platform.OS === 'ios' ? 75 : 50,
    maxWidth: layout.maxFeedWidth,
  },
})

const mapStateToProps = (state) => {
  return {
    announcement: state.announcement,
  }
}

export default connect(mapStateToProps, { setAnnouncement })(AppAnnouncement)
