import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { cancelJoinRequest, getJoinedGroupsList, joinGroupByID } from '../../../services/api/Groups'
import { CornerStyles, TypographyStyles } from '../../../styles'
import { ImageHighlight } from '../../images/ImageHighlight'
import { RoundBtn } from '../../buttons/StyledButtons'
import { FormInput } from '../../text/StyledTextinputs'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import Paths from '../../../constants/Paths'
import DataConsentDialog from './dialogs/DataConsentDialog'
import { NotificationBadge } from '../../icons/NotificationBadge'
import { useSelector } from 'react-redux'
import Card from '../../Card'
import { useNavigation } from '@react-navigation/core'
import CustomPressable from "../../pressables/CustomPressable"

/* groups users ranks
  1 - Admin
  2 - Moderator
  3 - Member
  4 - Awaiting approval
  5 - Disapproved
  6 - Invited
  7 - Declined invite
  8 - Banned
 */

export default function GroupItem(props) {
  const [password, setPassword] = useState('')
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [passwordError, setPasswordError] = useState(false)
  const [dataConsentGiven, setDataConsentGiven] = useState(false)
  const [dataDialogOpen, setDataDialogOpen] = useState(false)
  const [userRank, setUserRank] = useState(props.group.user_rank)
  const navigation = useNavigation()

  let badge = useSelector(
    state => (
      state.groups?.find(g => g.id === props.group.id)?.unread
    ),
  )

  useEffect(() => {
    //Updates badge if notification is read on another active device.
    const unsubscribe = navigation.addListener('focus', async () => {
      if (badge === 0) return
      const groups = await getJoinedGroupsList()
      const group = groups?.find((group) => group.id === props.group.id)
      if (group?.unread === props.group?.unread) return
      badge = group?.unread
    })
    return () => unsubscribe()
  }, [navigation])

  const accessButtonTitle = () => {
    const { type } = props.group
    if (userRank <= 3 && userRank !== null) return txt('groups.accessText.goTo')
    if (userRank === 4) return txt('groups.accessText.cancelApproval')
    if (userRank === 6) return txt('groups.accessText.invited')
    if (userRank === 8) return txt('groups.accessText.banned')
    if (type === 2) return txt('groups.accessText.password')
    if (type === 3) return txt('groups.accessText.requestAccess')
    if (type === 4) return txt('groups.accessText.join')
  }

  const handleCardClick = () => {
    if (props.group.type === 2 && userRank === null) handleAccessClick()
    else if (userRank === null) return
    else if (userRank >= 0 && userRank <= 3) props.navigateToGroup(props.group.id)
  }

  const askForDataConsent = async () => {
    setDataDialogOpen(true)
  }

  const handleCloseDataConsent = async () => {
    setDataDialogOpen(false)
    if (dataConsentGiven) {
      await joinGroup()
    }
  }

  async function joinGroup() {
    const { id } = props.group
    const responseMessage = await joinGroupByID(id, password, dataConsentGiven)

    if (responseMessage === 'INVITE ACCEPTED') {
      setUserRank(3)
      return props.navigateToGroup(id)
    }

    if (responseMessage === 'ACCESS REQUESTED') {
      return setUserRank(4)
    }

    if (responseMessage === 'WRONG PASSWORD') {
      return setPasswordError(true)
    }

    if (responseMessage === 'GROUP JOINED') {
      setUserRank(3)
      setPassword('')
      setShowPasswordPrompt(false)
      setPasswordHidden(true)
      setPasswordError(false)
      return props.navigateToGroup(id)
    }
  }

  const handleAccessClick = async () => {
    const { data_responsible, type } = props.group
    if (type === 2 && !userRank && userRank !== 0) {
      setShowPasswordPrompt(true)
      return
    }
    switch (userRank) {
      case 0:
      case 1:
      case 2:
      case 3:
        props.navigateToGroup(props.group.id)
        break
      case 4:
        await cancelApproval(props.group.id)
        break
      case 8:
        break
      case 5:
      case 6:
      case 7:
      default:
        if (data_responsible && !dataConsentGiven) {
          await askForDataConsent()
        } else {
          await joinGroup()
        }
    }
  }

  const cancelApproval = async (groupId) => {
    const res = await cancelJoinRequest(groupId)
    if (res === true) {
      setUserRank(null)
      setDataConsentGiven(false)
      await getJoinedGroupsList()
    }
  }

  const toggleDataConsent = () => {
    setDataConsentGiven(!dataConsentGiven)
  }

  const handleSendPassword = () => {
    if (props.group.data_responsible) {
      askForDataConsent()
    } else {
      joinGroup()
    }
  }

  const hidePasswordDialog = () => {
    setPasswordError(false)
    setShowPasswordPrompt(false)
    setPassword('')
  }
  return (
    <Card style={styles.cardContainer}>
      <View>
        <CustomPressable
          activeOpacity={0.8}
          onPress={handleCardClick}
          accessibilityRole="button"
          accessibilityLabel={txt('groups.groupLabel')}
          accessibilityHint={txt('groups.groupHint')}
        >
          <View style={styles.textContainer}>
            <View>
              <ImageHighlight
                source={{ uri: Paths.groupUrlBase + props.group.img_filename }}
                borderColor={Colors.black}
              />
              {!!badge && <NotificationBadge number={badge} style={styles.badge}/>}
            </View>
            <View style={styles.textContainerCard}>
              <CustomText style={styles.smallBold} numberOfLines={1}>
                {props.group.name}
              </CustomText>

              <View>
                <CustomText
                  style={styles.textSmall}
                  numberOfLines={Layout.isSmallDevice ? 5 : 6}
                >
                  {props.group.description}
                </CustomText>
              </View>
            </View>
          </View>
        </CustomPressable>
      </View>
      <View style={{ height: 1, backgroundColor: Colors.blackTransparent }} />
      <View>
        {showPasswordPrompt ? (
          <View style={styles.passwordContainer}>
            {/* password form */}
            <CustomPressable onPress={hidePasswordDialog} style={{alignSelf: 'flex-end'}}>
              <NormalIcon
                accessibilityRole="button"
                accessibilityLabel={txt('groups.close')}
                color={Colors.text}
                name="times"
                size={Fontsizes.xl}
              />
            </CustomPressable>
            <CustomText>{txt('groups.passwordDialog')}</CustomText>
            <View style={styles.marginTop}>
              {/* Wrong password prompt */}
              {passwordError && (
                <InvalidInputNoticeCentered>
                  {txt('groups.wrongPassword')}
                </InvalidInputNoticeCentered>
              )}
              <FormInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                onSubmitEditing={handleSendPassword}
                inputStyle={styles.inputText}
                accessibilityLabel={txt('groups.passwordPlaceHolder')}
                accessibilityHint={txt('groups.passwordHint')}
                placeholder={txt('groups.passwordPlaceHolder')}
                secureTextEntry={passwordHidden}
                onChangeText={setPassword}
                style={styles.inputText}
                rightIcon={
                  <CustomPressable
                    hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
                    onPress={() => setPasswordHidden(!passwordHidden)}
                    accessibilityRole="button"
                    accessibilityLabel={passwordHidden ? txt('loginField.passwordHide') : txt('loginField.passwordShow')}
                  >
                    <NormalIcon
                      name={ passwordHidden ? "eye-slash" : "eye"}
                      size={Fontsizes.l}
                      color={Colors.text}
                      stroke="fas"
                    />
                  </CustomPressable>
                }
              />
            </View>
            {/* Send passworded join request btn */}
            <View style={styles.btnContainer}>
              <RoundBtn
                title={txt('groups.accessText.access')}
                onPress={handleSendPassword}
              />
            </View>
          </View>
        ) : (
          <View style={styles.container}>
            <View style={styles.alignRight}>
              <RoundBtn
                disabled={userRank === 8}
                title={accessButtonTitle()}
                onPress={handleAccessClick}
              />
            </View>
          </View>
        )}
      </View>

      <DataConsentDialog
        open={dataDialogOpen}
        toggleConsent={toggleDataConsent}
        cancel={() => setDataDialogOpen(false)}
        send={handleCloseDataConsent}
        consent={dataConsentGiven}
        dataResponsible={props.group.data_responsible}
      />
    </Card>
  )
}

const styles = StyleSheet.create({
  alignRight: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 24,
  },
  btnContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 10,
  },
  cardContainer: {
    backgroundColor: Colors.white,
    margin: 12,
    marginVertical: 6,
    minHeight: 170,
  },
  container: {
    alignItems: 'flex-end',
    margin: 12,
  },
  inputText: { ...TypographyStyles.text },
  marginTop: { marginTop: 12 },
  passwordContainer: {
    padding: 12,
    ...CornerStyles.borderRadiusS,
  },
  smallBold: { ...TypographyStyles.textSmallBold },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: 12,
  },
  textContainerCard: { flexGrow: 1, flex: 1, marginLeft: 12, alignItems: 'flex-start' },
  textSmall: { ...TypographyStyles.textSmall },
  badge: {
    position: 'absolute',
    top: -2,
    right: -2,
  },
})
